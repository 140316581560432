import React from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";

Chart.register(CategoryScale);
const Charts = ({ chartData }) => {
  //console.log("🚀 ~ file: Charts.js:8 ~ Charts ~ chartData:", chartData);
  return (
    <Line
      style={{ background: "white" }}
      data={chartData}
      options={{
        animations: {
          y: {
            easing: "easeInOutElastic",
            from: (ctx) => {
              if (ctx.type === "data") {
                if (ctx.mode === "default" && !ctx.dropped) {
                  ctx.dropped = true;
                  return 0;
                }
              }
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: "Total bons de commandes par mois",
          },
          legend: {
            display: true,
          },
        },
      }}
    />
  );
};

export default Charts;
