import React from "react";
import { NavLink } from "react-router-dom";

const NavView = () => {
  return (
    <div className="nav-view">
      <NavLink to={`/employes`}>Détails employés</NavLink>
      <NavLink to={`/global`}>Global</NavLink>
    </div>
  );
};

export default NavView;
