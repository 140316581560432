import React, { useState } from "react";
import instance from "../axios/global";

const ImportFile = () => {
  const [file, setFile] = useState();

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    instance
      .post("/bdc/bulk2", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
      });
  };
  console.log("file :>> ", file);
  return (
    <div>
      <header>
        <h1>Import BDC</h1>
      </header>
      <div style={{ textAlign: "center" }}>
        <div>
          <form onSubmit={handleSubmit}>
            <input type="file" accept=".csv" onChange={handleFileUpload} />
            <button className="button-import-file" type="submit">
              Import CSV
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ImportFile;
