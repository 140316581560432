import React, { useState, useEffect, useContext } from "react";
import DisplayAllTotal from "../components/DisplayAllTotal";
import instance from "../axios/global";
import { AuthContext } from "../auth/auth";
import { SegmentedControl } from "@mantine/core";
import NavView from "../components/NavView";

const GlobalEmployes = () => {
  const [loading, setLoading] = useState(false);
  const { authState } = useContext(AuthContext);
  const [dataAll, setDataAll] = useState([]);
  const [thAll, setThAll] = useState([]);
  const [thList, setThList] = useState([]);
  const [gape, setGape] = useState("15");
  const [total, setTotal] = useState([]);

  const totalValue = total?.reduce((acc, cuur) => {
    return (acc += cuur);
  }, 0);

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      try {
        const res = await instance.get(
          `/user/userbdc/all?gape=${gape}&role=${authState.role}`
        );
        setLoading(false);
        setTotal(
          res?.data.map((el) => {
            return el.total_bdc;
          })
        );

        const formatData = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }

          const result = data.flatMap((curr) => {
            const { id, firstName, lastName, total_bdc, roles, row, image } =
              curr;

            const flat = row?.flatMap((el) => el?.cell) || [];
            return flat.map(({ date, value }) => ({ date, value }));
          });

          result.sort((a, b) => a.date.localeCompare(b.date));

          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});

          return [
            {
              //id: data[0].id,
              Nom: data[0].lastName,
              Prenom: data[0].firstName,

              "Total BDC": data[0].total_bdc,
              //image: data[0].image,
              expertise: data[0].expertise,
              ...formattedData,
            },
          ];
        };
        const formatData2 = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }

          const result = data.flatMap((curr) => {
            const { row } = curr;

            const flat = row?.flatMap((el) => el?.cell) || [];
            return flat.map(({ date, value }) => ({ date, value }));
          });

          result.sort((a, b) => a.date.localeCompare(b.date));

          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});

          return [
            {
              ...formattedData,
            },
          ];
        };
        setThAll(formatData2(res?.data));
        //console.log(thAll)
        setThList(formatData(res?.data));
        setDataAll(
          res?.data?.map((curr) => {
            const { row } = curr;
            const cell = row?.map((el) => {
              return el?.cell;
            });
            const flat = cell?.flat();
            const result = flat.reduce((accumulator, obj) => {
              let { date, value } = obj;
              // date = moment(date).format("MM-YYYY");
              if (!accumulator[date]) {
                accumulator[date] = 0;
              }
              accumulator[date] += value;
              return accumulator;
            }, {});

            const aggregatedResult = Object.entries(result).map(
              ([date, value]) => {
                return { date, value };
              }
            );

            aggregatedResult.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });
            return {
              ...Object.fromEntries(
                aggregatedResult.map(({ date, value }) => [date, value])
              ),
            };
          })
        );

        // setDataToDisplay(
        //   res?.data?.map((curr) => {
        //     const {
        //       id,
        //       firstName,
        //       lastName,
        //       total_bdc,
        //       expertise,
        //       row,
        //       image,
        //     } = curr;
        //     const cell = row?.map((el) => {
        //       return el?.cell;
        //     });
        //     const flat = cell?.flat();
        //     const result = flat.reduce((accumulator, obj) => {
        //       let { date, value } = obj;
        //       // date = moment(date).format("MM-YYYY");
        //       if (!accumulator[date]) {
        //         accumulator[date] = 0;
        //       }
        //       accumulator[date] += value;
        //       return accumulator;
        //     }, {});

        //     const aggregatedResult = Object.entries(result).map(
        //       ([date, value]) => {
        //         return { date, value: `${value.toLocaleString()}€` };
        //       }
        //     );

        //     aggregatedResult.sort((a, b) => {
        //       if (a.date < b.date) {
        //         return -1;
        //       } else if (a.date > b.date) {
        //         return 1;
        //       } else {
        //         return 0;
        //       }
        //     });
        //     return {
        //       id,
        //       Prenom: firstName,
        //       Nom: lastName,
        //       "Total BDC": `${total_bdc.toLocaleString()}€`,
        //       image,
        //       expertise,
        //       ...Object.fromEntries(
        //         aggregatedResult.map(({ date, value }) => [date, value])
        //       ),
        //     };
        //   })
        // );
      } catch (error) {
        console.log(
          "🚀 ~ file: Employes.js:49 ~ fetchEmployees ~ error",
          error
        );
      }
    };
    fetchEmployees();
  }, [gape]);
  return (
    <div className="employes">
      <header>
        <div>
          <h1>Liste des employés</h1>
        </div>
        <div
          className="descrip-totals"
          style={{ marginTop: "2rem", marginRight: "3rem" }}
        >
          <p>Total bon de commandes attribué</p>

          <span>
            {console.log(
              "🚀 ~ file: Employes.js:686 ~ Employes ~ totalValue:",
              totalValue
            )}
            {totalValue ? `${totalValue.toLocaleString()}€` : `${0}€`}
          </span>
        </div>
      </header>
      <div className="search-create">
        <NavView />
        <div>
          <SegmentedControl
            value={gape}
            onChange={setGape}
            data={[
              { label: "12", value: "12" },
              { label: "12 + 3", value: "15" },
              { label: "24", value: "24" },
              { label: "All", value: "all" },
            ]}
          />
        </div>
      </div>
      {loading ? null : (
        <div>
          <span className="total-mois">Total par mois</span>
          <DisplayAllTotal dataAll={dataAll} thAll={thAll} />
        </div>
      )}
    </div>
  );
};

export default GlobalEmployes;
