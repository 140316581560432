import React, { useEffect, useState } from "react";
import instance from "../axios/global";
import LoaderComp from "../components/LoaderComp";
import moment from "moment";
import { Modal } from "@mantine/core";
import edit from "../assets/Edit.svg";
import trash from "../assets/Trash-2.svg";
import { toast } from "react-toastify";
import { useTable } from "react-table";
import DisplayAllTotal from "../components/DisplayAllTotal";
import StickyTable from "react-sticky-table-thead";
import euro from "../assets/euro.svg";

const DetailsBdc = () => {
  const currentUrl = window.location.href;
  const regex = /\/clients\/([^/]+)\/details-bdc\/([^/]+)/;
  const matches = currentUrl.match(regex);
  let clientId;
  let detailsId;
  if (matches) {
    clientId = matches[1];
    detailsId = matches[2];
  }
  const [bdcInfos, setBdcInfos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [opened2, setOpened2] = useState(false);
  const [idBdc, setIdBdc] = useState("");
  const [userId, setUserId] = useState("");
  const [allConsultantsAssigned, setAllConsultantsAssigned] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [thAll, setThAll] = useState([]);
  const [thList, setThList] = useState([]);
  const [totalSum, setTotalSum] = useState(0.0);
  console.log("🚀 ~ file: DetailsBdc.js:34 ~ DetailsBdc ~ totalSum:", totalSum);

  const [dataToDisplay, setDataToDisplay] = useState([]);

  useEffect(() => {
    const fetchBdcInfos = async () => {
      setLoading(true);
      try {
        const res = await instance.get(
          `bdc/client/user/${detailsId}/${clientId}`
        );
        setAllConsultantsAssigned(res.data.user);
        setBdcInfos(res.data);
        const formatData = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }
          const result = data.flatMap((curr) => {
            const { id, firstName, lastName, total_bdc, roles, row, image } =
              curr;

            const flat = row?.flatMap((el) => el?.cell) || [];

            return flat.map(({ date, value }) => ({ date, value }));
          });

          result.sort((a, b) => a.date.localeCompare(b.date));
          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});
          return [
            {
              Number: 1234,
              Nom: "Nom bdc",
              Expertise: data[0].Bdc_User.userRole,
              Consultant: data[0].lastName,
              "Total BDC": data[0].total_bdc,
              ...formattedData,
            },
          ];
        };
        const formatData2 = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }
          const result = data.flatMap((curr) => {
            const { id, firstName, lastName, total_bdc, roles, row, image } =
              curr;

            const flat = row?.flatMap((el) => el?.cell) || [];

            return flat.map(({ date, value }) => ({ date, value }));
          });

          result.sort((a, b) => a.date.localeCompare(b.date));
          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});
          return [
            {
              ...formattedData,
            },
          ];
        };
        setThList(formatData(res?.data?.user));
        setThAll(formatData2(res?.data?.user));
        setDataAll(
          res?.data.user?.map((curr) => {
            const { firstName, lastName, row, image, Bdc_User } = curr;
            const cell = row?.map((el) => {
              return el?.cell;
            });
            const flat = cell?.flat();
            const result = flat.flatMap((obj) => {
              let { date, value } = obj;
              // date = moment(date).format("MM-YYYY");
              return [{ date, value }];
            });
            result.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });

            return {
              ...Object.fromEntries(
                result.map(({ date, value }) => [
                  date,
                  // `${value.toLocaleString()}`,
                  value,
                ])
              ),
            };
          })
        );
        setDataToDisplay(
          res?.data.user?.map((curr) => {
            const { firstName, lastName, row, image, Bdc_User } = curr;
            const cell = row?.map((el) => {
              return el?.cell;
            });
            const flat = cell?.flat();
            const result = flat.flatMap((obj) => {
              let { date, value } = obj;
              // date = moment(date).format("MM-YYYY");
              return [{ date, value }];
            });
            const totalValue = result.reduce(
              (acc, curr) => acc + parseInt(curr.value),
              0.0
            );

            result.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });

            return {
              Number: res.data.number,
              Nom: res.data.name,
              "Total BDC": `${totalValue.toLocaleString()}€`,
              image,
              Expertise: Bdc_User.userRole,
              Consultant: lastName + " " + firstName.slice(0, 2),
              ...Object.fromEntries(
                result.map(({ date, value }) => [
                  date,
                  `${parseInt(value).toLocaleString()}€`,
                ])
              ),
            };
          })
        );
        const grandTotalValue = res?.data.user?.reduce((accumulator, curr) => {
          const cell = curr.row?.map((el) => el?.cell).flat();
          const totalValue = cell
            ?.flatMap((obj) => ({
              date: obj.date,
              value: parseFloat(obj.value),
            }))
            .reduce((acc, curr) => acc + curr.value, 0.0);
          return accumulator + totalValue;
        }, 0.0);

        setTotalSum(grandTotalValue);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("Error:", error);
      }
    };

    fetchBdcInfos();
  }, []);

  const data = React.useMemo(() => dataToDisplay, [dataToDisplay]);
  const columns = React.useMemo(() => {
    if (!thList || thList.length === 0) {
      return []; // Retourner une liste de colonnes vide si thList est undefined ou vide
    }

    // Générer dynamiquement les colonnes à partir des clés des objets de données
    const keys = Object.keys(thList[0]);
    /*     const dynamicColumns = keys.map((key) => ({
      Header: key,
      accessor: key,
    }));

    return dynamicColumns; */
    const dynamicColumns = keys.map((key, index) => {
      let headerGroup;
      if (index < 5) {
        headerGroup = "infos";
      } else {
        headerGroup = "date";
      }

      return {
        Header: key,
        accessor: key,
        headerGroup, // Utiliser le nom du header group correspondant
      };
    });

    return [
      {
        Header: "Information",
        columns: dynamicColumns.slice(0, 5), // Les 3 premiéres colonnes pour le header group "Infos", // Les 4 premières colonnes pour le header group "Infos"
        sticky: "left",
      },
      {
        Header: "Date",
        columns: dynamicColumns.slice(5), // Les colonnes restantes pour le header group "Date"
      },
    ];
  }, [thList]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <div className="bdc-page">
      <header>
        <h1>Détails bon de commande</h1>
      </header>

      {/* {dataToDisplay.length !== 0 ? (
        <DisplayAllTotal dataAll={dataAll} thAll={thAll} />
      ) : null} */}

      {dataToDisplay.length === 0 ? (
        <div>Pas d'employé avec BDC à afficher</div>
      ) : loading ? (
        <LoaderComp />
      ) : (
        <StickyTable height={"100%"}>
          <table className="table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </StickyTable>
      )}
    </div>
  );
};

export default DetailsBdc;
