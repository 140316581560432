import styled from "styled-components";
export const Styles = styled.div`
  .table {
    /* border: 1px solid #ddd; */

    .tr {
      text-transform: uppercase;

      font-size: 14px;
    }

    th {
      padding: 10px 10px;
    }

    td {
      font-family: "InterRegular";
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.05em;
      color: #2f2b43;
      text-align: left;
      padding: 10px 10px;
      border-bottom: 1px solid #faf9fa;
    }

    tr:nth-child(even) {
      background-color: #f1f1f1; /* Couleur de fond gris pour les td pairs */
    }

    .th .td {
      padding: 5px;
      font-family: "InterMedium";
      position: sticky;
      font-size: 14px;
      text-align: "left";
      /* identical to box height */
      display: "flex";
      align-items: "center";
      letter-spacing: -0.05em;
      overflow: hidden;
      background-color: #ffffff !important;
      color: #1b1b1b;

      :last-child {
        border-right: 0;
      }
    }

    &.sticky {
      overflow-x: scroll;
      .header,
      .header {
        border-radius: 0.625rem 0.625rem 0rem 0rem;
      }
      .body {
        position: relative;
        z-index: 0;
      }

      .th {
        color: gray;
        font-size: 14px;
      }

      [data-sticky-td] {
        position: sticky;
        background: white;
        font-family: "InterRegular";
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.05em;

        text-align: left;
      }

      [data-sticky-last-left-td]:nth-child(even) {
        background-color: #f1f1f1; /* Couleur de fond gris pour les td pairs */
      }

      [data-sticky-td]:hover {
      }
      [data-sticky-last-th] {
      }
      [data-sticky-last-left-td] {
      }

      [data-sticky-first-right-td] {
      }
    }
  }
`;
