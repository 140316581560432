import React from "react";
import important from "../assets/warning-filled.svg";

const ImportantErrorMessage = ({ message, comp }) => {
  return (
    <div className="container-warning-msg">
      <div
        style={{
          color: "red",
          display: "flex",
          marginLeft: comp === "importbdc" ? "3rem" : "",
        }}
        className="logo-and-title"
      >
        <img src={important} alt="important" /> <h3>Important</h3>
      </div>
      {message.map((msg, index) => (
        <p
          style={{
            color: "black",
            marginLeft: comp === "importbdc" ? "3rem" : "",
          }}
          key={index}
        >
          - {msg}
        </p>
      ))}
    </div>
  );
};

export default ImportantErrorMessage;
