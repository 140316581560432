import React, { useEffect, useState } from "react";
import instance from "../axios/global";
import { NavLink, useNavigate } from "react-router-dom";
import edit from "../assets/Edit.svg";
import trash from "../assets/Trash-2.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import LoaderComp from "../components/LoaderComp";
import { Modal, useMantineTheme } from "@mantine/core";
import NavBdc from "../components/NavBdc";
import { useParams } from "react-router-dom";

const BdcByTeam = () => {
  const { id } = useParams();
  const [userBdcList, setUserBdcList] = useState([]);
  const [thList, setThList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [idBdc, setIdBdc] = useState("");

  useEffect(() => {
    const fetchBdc = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/bdc/user/${id}`);
        setUserBdcList(res?.data);
        console.log("res.data :>> ", res.data);
        setThList(
          res?.data?.map((el) => {
            const obg = Object.keys(el);
            return obg;
          })
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetchBdc();
  }, []);

  console.log(id);

  const filteredBdcList = userBdcList?.filter((bdc) => {
    const query = searchQuery.toLowerCase();
    return (
      bdc?.number?.toString().toLowerCase().includes(query) || // search by number
      bdc?.name?.toLowerCase().includes(query) // search by name
    );
  });

  const handleDeleteBdc = async (id) => {
    try {
      const res = await instance.delete(`/bdc/delete/${id}`);
      setOpened(false);
      toast.success("Bon de commande supprimé.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    } catch (error) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_LEFT,
      });
      console.log("error :>> ", error);
    }
  };

  const rows =
    filteredBdcList &&
    filteredBdcList.map((element) => {
      const id = element.id;
      const startDate = moment(element.start_date, "YYYY-MM").format("MM-YYYY");
      const endDate = moment(element.end_date).format("MM-YYYY");

      let status = ""; // Statut par défaut
      let statusClass = ""; // Classe CSS par défaut

      if (element.totalAttribution === 0) {
        status = "Non attribué";
        statusClass = "status-non-attribue";
      } else if (element.totalAttribution === 100) {
        status = "Attribué";
        statusClass = "status-attribue";
      } else {
        status = "Attribué à " + element.totalAttribution + "%";
        statusClass = "status-partiellement-attribue";
      }

      return (
        <tr key={element.id}>
          <td>{element?.number ?? 0}</td>
          <td
            style={{
              maxWidth: "200px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {element?.client_name}
          </td>
          <td
            style={{
              maxWidth: "300px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {element?.name ? element.name : "no name"}
          </td>
          <td>{element?.amount_ttc?.toLocaleString() ?? 0}€</td>
          <td>{startDate}</td>
          <td>{endDate}</td>
          <td>{element?.estimated_amount?.toLocaleString() ?? 0}€</td>
          <div className={statusClass}>{status}</div>

          <td>
            <img
              onClick={() => {
                navigate(`/bdc/update/${id}`);
              }}
              className="icon-action"
              src={edit}
              alt="edit"
            />

            <img
              className="icon-action"
              src={trash}
              alt="delete"
              onClick={() => {
                setOpened(true);
                setIdBdc(id);
              }}
            />
          </td>
        </tr>
      );
    });

  console.log("idBdc :>> ", idBdc);
  return (
    <div className="bdc-page">
      <header>
        <h1>Liste des bons de commande</h1>
      </header>
      <NavBdc />
      <section>
        <Modal
          opened={opened}
          onClose={() => setOpened(false)}
          overlayOpacity={0.55}
          overlayBlur={3}
          transition="fade"
          transitionDuration={600}
          transitionTimingFunction="ease"
          size={650}
          padding={50}
        >
          <div className="container-confirm-delete">
            <div className="confirm-msg-delete">
              <p> Etes-vous sûr de vouloir supprimer cette affaire ?</p>
              <span>
                Vous ne pourrez plus revenir en arrière. Le bon de commande sera
                définitivement supprimer ainsi que toutes ses attributions
              </span>
            </div>
            <div className="div-button-choice">
              <button className="btn-no" onClick={() => setOpened(false)}>
                Annuler
              </button>
              <button
                className="btn-yes"
                onClick={() => handleDeleteBdc(idBdc, console.log(idBdc))}
              >
                Supprimer
              </button>
            </div>
          </div>
        </Modal>
        <div className="import-search-div">
          <div className="search-create">
            {userBdcList.length !== 0 ? (
              <div>
                <input
                  className="search-bar"
                  type="text"
                  placeholder="Recheche bon de commande"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            ) : (
              ""
            )}
            <div>
              {/* <button
                style={{ cursor: "pointer", marginRight: "1rem" }}
                onClick={() => {
                  navigate(`/creerBDC`);
                }}
              >
                Ajouter un BDC
              </button> */}

              <button
                className="btn-import-bdc"
                style={{ cursor: "pointer", marginRight: " 12px" }}
                onClick={() => {
                  navigate(`/bdc/importAttribution`);
                }}
              >
                Importer Attribution
              </button>
              <button
                className="btn-import-bdc"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/bdc/importBDC`);
                }}
              >
                Importer BDC
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <LoaderComp />
        ) : (
          <div className="container-table">
            {userBdcList.length > 0 ? (
              <table className="table">
                <thead className="thead-bdcs sticky-table-head">
                  <tr>
                    <th>Numéro affaire</th>
                    <th>Client</th>
                    <th>Nom</th>
                    <th>Montant TTC</th>
                    <th>Date de debut</th>
                    <th>Date de fin</th>
                    <th>Marge estimée</th>
                    <th>Statut</th>
                    {/* {thList.map((el, index) => {
                  return <th key={index}>{el}</th>;
                })} */}
                    {/* {bdcList.length !== 0
                  ? bdcList.map((el) => {
                      return el.cell.map((cell) => {
                        return <th key={cell.id}>{cell.date}</th>;
                      });
                    })
                  : null} */}
                    {/* {column[0]?.map((el, index) => {
                  return <th key={index}>{el}</th>;
                })} */}
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            ) : (
              <p>Vous n'avez pas encore enregistré de bon de commandes</p>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default BdcByTeam;
