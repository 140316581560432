import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../auth/auth";

const RequireAuth = () => {
  const { authState } = useContext(AuthContext);
  const location = useLocation();

  if (!authState.status) {
    // Redirige vers la page de connexion, mais garde l'URL actuelle en mémoire
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Si l'utilisateur est authentifié, affiche le contenu de la route
  return <Outlet />;
};

export default RequireAuth;
