import React, { useState } from "react";
import { useTable, useBlockLayout, useGlobalFilter } from "react-table";
import { useSticky } from "react-table-sticky";
import StickyTable from "react-sticky-table-thead";
import { Styles } from "../components/TableStyles";
import { getClass } from "../utils/utils";

const DisplayAllTotal = ({ dataAll, thAll }) => {
  const data = React.useMemo(() => {
    const aggregatedData = {};

    for (const item of dataAll) {
      for (const key in item) {
        if (aggregatedData[key]) {
          aggregatedData[key] += item[key];
        } else {
          aggregatedData[key] = item[key];
        }
      }
    }

    const formattedData = {};

    for (const key in aggregatedData) {
      formattedData[key] = `${parseInt(aggregatedData[key]).toLocaleString()}€`;
    }

    return [formattedData];
  }, [dataAll]);
  const columns = React.useMemo(() => {
    if (!thAll || thAll.length === 0) {
      return []; // Retourner une liste de colonnes vide si thAll est undefined ou vide
    }

    // Générer dynamiquement les colonnes à partir des clés des objets de données
    const keys = Object.keys(thAll[0]);
    const dynamicColumns = keys.map((key) => ({
      Header: key,
      accessor: key,
      width: 110,
    }));

    return dynamicColumns;
  }, [thAll]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useBlockLayout, useSticky, useGlobalFilter);
  return (
    <StickyTable>
      <table {...getTableProps()} className="table-total">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onClick={() => {}}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      <div className={getClass(cell.value)}>
                        {cell.render("Cell")}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </StickyTable>
  );
};

export default DisplayAllTotal;
