import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Accueil from "./pages/Accueil";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "./auth/auth";
import RequireAuth from "./hooks/RequireAuth";

function App() {
  const [authState, setAuthState] = useState({
    id: "",
    name: "",
    email: "",
    role: "",
    status: false,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setAuthState({
        id: user.id,
        name: user.firstName,
        email: user.email,
        role: user.role,
        status: true,
      });
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Chargement...</div>; // Ou un composant de chargement
  }

  return (
    <div className="App">
      <BrowserRouter>
        <AuthContext.Provider value={{ authState, setAuthState }}>
          <Routes>
            <Route
              path="/login"
              element={
                authState.status ? (
                  <Navigate to="/employes" replace />
                ) : (
                  <Login />
                )
              }
            />
            <Route element={<RequireAuth />}>
              <Route path="/*" element={<Accueil />} />
            </Route>
            <Route
              path="/"
              element={
                authState.status ? (
                  <Navigate to="/employes" replace />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
          </Routes>
          <ToastContainer />
        </AuthContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
