import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../auth/auth";

const NavBdc = () => {
  const { authState } = useContext(AuthContext);
  return (
    <div className="nav-bdc">
      <NavLink to={`/bdc`}>Liste bon de commande</NavLink>
      {/* <NavLink to={`/teambdcs`}>Bons de ma team</NavLink> */}
      <NavLink to={`/mes-bdc/${authState.id}`}>Mes bons de commandes</NavLink>
      {/*  <NavLink to={`/liste-bdc-a-modifier`}>Bon de commande à modifier</NavLink> */}
      <NavLink to={`/mes-bdc-fix/${authState.id}`}>
        Bons de commandes figés
      </NavLink>
      {/*  <NavLink to={`/liste-bdc-a-modifier`}>Bon de commande à modifier</NavLink> */}
    </div>
  );
};

export default NavBdc;
