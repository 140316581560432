import React from "react";
import { NavLink } from "react-router-dom";

const NavClient = () => {
  return (
    <div className="nav-view">
      <NavLink to={`/clients`}>Détails Clients</NavLink>
      <NavLink to={`/global-clients`}>Global</NavLink>
    </div>
  );
};

export default NavClient;
