export const getClass = (value) => {
  if (typeof value === "string") {
    const numericValue = parseInt(value.replace(/\s/g, ""), 10);

    if (isNaN(numericValue)) {
      return ""; // Valeur par défaut si la conversion échoue
    }

    if (numericValue < 2500) {
      return "no-background";
    } else if (numericValue < 7500) {
      return "pink-background";
    } else if (numericValue < 10500) {
      return "beige-background";
    } else if (numericValue < 12500) {
      return "green-background";
    } else if (numericValue < 15000) {
      return "dark-green-background";
    } else {
      return "orange-background";
    }
  }

  return ""; // Valeur par défaut si value n'est pas une chaîne de caractères valide
};
