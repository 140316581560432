import React from "react";
import { Routes, Route } from "react-router-dom";
import CreationBDC from "./CreationBDC";
import Clients from "./Clients";
import Employes from "./Employes";
import Bdc from "./Bdc";
import BdcContextProvider from "../context/bdcContext";
import ImportBDC from "./ImportBDC";
import ClientBDC from "./ClientBDC";
import EditBDC from "./EditBDC";
import BdcByUser from "./BdcByUser";
import Navbar from "../components/NavBar";
import CreateBdcContextProvider from "../context/createBdcContext";
import ImportFile from "./ImportFile";
import ListBdcToCheck from "./ListBdcToCheck";
import BdcToCheck from "./BdcToCheck";
import DetailsBdc from "./DetailsBdc";
import ImportAttribution from "./ImportAttribution";
import UserBdc from "./UserBdc";
import RequireAuth from "../hooks/RequireAuth";
import BdcByTeam from "./BdcByTeam";
import GlobalEmployes from "./GlobalEmployes";
import GlobalClients from "./GlobalClients";
import FixBdc from "./FixBdc";

const Accueil = () => {
  return (
    <>
      <Navbar />
      <div className="accueil">
        <div className="global-container">
          <BdcContextProvider>
            <CreateBdcContextProvider>
              <Routes>
                <Route element={<RequireAuth />}>
                  <Route path="/employes" element={<Employes />}></Route>
                  <Route path="/global" element={<GlobalEmployes />}></Route>
                  <Route path="/global-clients" element={<GlobalClients />} />

                  <Route
                    path="/employes/bdcbyuser/:id"
                    element={<BdcByUser />}
                  />
                  <Route path="/bdc" element={<Bdc />}></Route>
                  <Route path="/teambdcs" element={<BdcByTeam />}></Route>
                  <Route path="/mes-bdc/:id" element={<UserBdc />}></Route>
                  <Route path="/mes-bdc-fix/:id" element={<FixBdc />}></Route>
                  <Route path="/bdc/importBDC" element={<ImportBDC />} />
                  <Route path="/bdc/update/:id" element={<EditBDC />} />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/bdc/importFile" element={<ImportFile />} />
                  <Route
                    path="/bdc/importAttribution"
                    element={<ImportAttribution />}
                  />
                  <Route path="/creerBdc" element={<CreationBDC />} />
                  <Route
                    path="/liste-bdc-a-modifier"
                    element={<ListBdcToCheck />}
                  />
                  <Route
                    path="/clients/:id/details-bdc/:id"
                    element={<DetailsBdc />}
                  />
                  <Route path="/clients/:id" element={<ClientBDC />} />
                  <Route
                    path="/bdc/bdc-a-modifier/:id"
                    element={<BdcToCheck />}
                  />
                </Route>
              </Routes>
            </CreateBdcContextProvider>
          </BdcContextProvider>
        </div>
      </div>
    </>
  );
};

export default Accueil;
