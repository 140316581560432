import React, { useEffect, useState, useContext, useMemo } from "react";
import { Select, Modal, useMantineTheme } from "@mantine/core";
import instance from "../axios/global";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getClass } from "../utils/utils";
import { SegmentedControl, Checkbox } from "@mantine/core";
import { useTable, useBlockLayout, useGlobalFilter } from "react-table";
import Papa from "papaparse";
import { AuthContext } from "../auth/auth";
import LoaderComp from "../components/LoaderComp";
import { useSticky } from "react-table-sticky";
import { Styles } from "../components/TableStyles";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GlobalFilter } from "../components/GlobalFilter";

import NavView from "../components/NavView";

const Employes = () => {
  const [opened, setOpened] = useState(false);
  const { authState, setAuthState } = useContext(AuthContext);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [image, setImage] = useState("");
  //const [password, setPassword] = useState();
  const [expertise, setExpertise] = useState();
  const [roles, setRoles] = useState();
  const [entity, setEntity] = useState();
  const [squad, setSquad] = useState();
  const [isActive, setIsActive] = useState();
  const [yuriId, setYuriId] = useState();
  const theme = useMantineTheme();
  const [thList, setThList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [showInactiveEmployees, setShowInactiveEmployees] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [thAll, setThAll] = useState([]);
  const [gape, setGape] = useState("15");

  const [total, setTotal] = useState([]);
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    // Fonction pour faire défiler jusqu'en haut de la page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Pour une animation de défilement en douceur
    });
  };
  window.addEventListener("scroll", handleScroll);

  const userSchema = yup.object().shape({
    firstName: yup.string().required("Veuillez renseigner le prénom"),
    lastName: yup.string().required("Veuillez renseigner le nom"),
    email: yup
      .string()
      .email("Adresse email non valide ou deja utilisée")
      .required("Adresse email non renseignée")
      .matches(
        "yuriandneil.com",
        "Vous devez entre une adresse email contenant yuriandneil.com"
      ),
    // password: yup
    //   .string()
    //   .required("Le mot de passe est requis")

    //   .min(6, "Le mot de passe doit comporter au minimum 6 caractères")
    //   .max(20, "Le mot de passe doit comporter au maximum 20 caractères"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    instance
      .get("user/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          setAuthState({ ...authState, status: false });
        } else {
          localStorage.setItem("user", JSON.stringify(res.data));
          console.log("res.dataAAAAAA :>> ", res.data);
          setAuthState({
            email: res.data.email,
            name: res.data.firstName,
            id: res.data.id,
            image: res.data.image,
            status: true,
            role: res.data.role,
            squad: res.data.Squad,
            brand: res.data.Brand,
          });
        }
      });
  }, []);

  const handleRightClick = (event, id) => {
    const cmdOrCtrlPressed = event.metaKey || event.ctrlKey;
    if (cmdOrCtrlPressed) {
      event.preventDefault();
      const url = `/employes/bdcbyuser/${id}`;
      const newTab = window.open(url, "_blank", "noopener,noreferrer");
      if (newTab) {
        newTab.opener = window;
      }
    }
  };

  const data = React.useMemo(() => dataToDisplay, [dataToDisplay]);
  const filteredData = useMemo(() => {
    if (showInactiveEmployees) {
      // Si la checkbox est cochée, affichez tous les employés
      return data;
    } else {
      // Si la checkbox est décochée, filtrez uniquement les employés actifs
      return data.filter((employee) => employee.isActive === true);
    }
  }, [data, showInactiveEmployees]);

  const memoizedData = React.useMemo(() => thList, [thList]);

  const columns = React.useMemo(() => {
    if (!thList || thList.length === 0) {
      return []; // Retourner une liste de colonnes vide si thList est undefined ou vide
    }

    // Générer dynamiquement les colonnes à partir des clés des objets de données
    const keys = Object.keys(thList[0]);

    const dynamicColumns = keys.map((key, index) => {
      let headerGroup;
      if (index < 4) {
        headerGroup = "infos";
      } else {
        headerGroup = "date";
      }

      return {
        Header: key,
        accessor: key,
        headerGroup, // Utiliser le nom du header group correspondant
        width: 85,
      };
    });

    return [
      {
        Header: "Information",
        columns: dynamicColumns.slice(0, 3), // Les 4 premières colonnes pour le header group "Infos"
        sticky: "left",
      },
      {
        Header: "Date",
        columns: dynamicColumns.slice(3), // Les colonnes restantes pour le header group "Date"
      },
    ];
  }, [thList]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: filteredData,
    },
    useBlockLayout,
    useSticky,
    useGlobalFilter
  );

  const { globalFilter } = state;

  const totalValue = total?.reduce((acc, cuur) => {
    return (acc += cuur);
  }, 0);

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      try {
        const res = await instance.get(
          `/user/userbdc/all?gape=${gape}&role=${authState.role}`
        );
        setLoading(false);
        // setShowInactiveEmployees(
        //   res?.data.map((el) => {
        //     return el.isActive;
        //   })
        // );
        const activeEmployees = res?.data?.filter((el) => el.isActive);
        console.log("activeEmployees 56925714 :>> ", activeEmployees);
        setFilteredEmployees(activeEmployees);
        setTotal(
          res?.data.map((el) => {
            return el.total_bdc;
          })
        );

        const formatData = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }

          const result = data.flatMap((curr) => {
            const {
              id,
              firstName,
              lastName,
              total_bdc,
              roles,
              row,
              image,
              isActive,
            } = curr;

            const flat = row?.flatMap((el) => el?.cell) || [];
            return flat.map(({ date, value }) => ({ date, value }));
          });

          result.sort((a, b) => b.date.localeCompare(a.date));

          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});

          return [
            {
              //id: data[0].id,

              Nom: data[0]?.lastName + data[0]?.firstName,
              //  Prenom: data[0].firstName,

              "Total BDC": data[0]?.total_bdc,
              //image: data[0].image,
              expertise: data[0]?.expertise,
              ...formattedData,
            },
          ];
        };
        const formatData2 = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }

          const result = data.flatMap((curr) => {
            const { row } = curr;

            const flat = row?.flatMap((el) => el?.cell) || [];
            return flat.map(({ date, value }) => ({ date, value }));
          });

          result.sort((a, b) => b.date.localeCompare(a.date));

          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});

          return [
            {
              ...formattedData,
            },
          ];
        };
        setThAll(formatData2(res?.data));

        setThList(formatData(res?.data));
        setDataAll(
          res?.data?.map((curr) => {
            const { row } = curr;
            const cell = row?.map((el) => {
              return el?.cell;
            });
            const flat = cell?.flat();
            const result = flat.reduce((accumulator, obj) => {
              let { date, value } = obj;
              // date = moment(date).format("MM-YYYY");
              if (!accumulator[date]) {
                accumulator[date] = 0;
              }
              accumulator[date] += value;
              return accumulator;
            }, {});

            const aggregatedResult = Object.entries(result).map(
              ([date, value]) => {
                return { date, value };
              }
            );

            aggregatedResult.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });
            return {
              ...Object.fromEntries(
                aggregatedResult.map(({ date, value }) => [
                  date,
                  parseInt(value),
                ])
              ),
            };
          })
        );

        setDataToDisplay(
          res?.data?.map((curr) => {
            const {
              id,
              firstName,
              lastName,
              total_bdc,
              expertise,
              row,
              image,
              isActive,
            } = curr;

            const cell = row?.map((el) => {
              return el?.cell;
            });
            const flat = cell?.flat();
            const result = flat.reduce((accumulator, obj) => {
              let { date, value } = obj;
              // date = moment(date).format("MM-YYYY");
              if (!accumulator[date]) {
                accumulator[date] = 0;
              }
              accumulator[date] += value;
              return accumulator;
            }, {});

            const aggregatedResult = Object.entries(result).map(
              ([date, value]) => {
                return { date, value: `${parseInt(value).toLocaleString()}€` };
              }
            );

            aggregatedResult.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });
            return {
              id,
              isActive: isActive,
              Nom: lastName[0] + "." + firstName,
              "Total BDC": `${parseInt(total_bdc).toLocaleString()}€`,
              // image,
              expertise,
              ...Object.fromEntries(
                aggregatedResult.map(({ date, value }) => [date, value])
              ),
            };
          })
        );
      } catch (error) {
        console.log(
          "🚀 ~ file: Employes.js:49 ~ fetchEmployees ~ error",
          error
        );
      }
    };
    fetchEmployees();
  }, [gape, authState.role]);

  const entité = [
    { value: "R&D", label: "R&D", name: "entity" },
    { value: "YN Content", label: "YN Content", name: "entity" },
    { value: "YN Data", label: "YN Data", name: "entity" },
    { value: "YN Group", label: "YN Group", name: "entity" },
    { value: "YN Learning", label: "YN Learning", name: "entity" },
    { value: "YN Media ", label: "YN Media", name: "entity" },
    { value: "YN Social Media", label: "YN Social Media", name: "entity" },

    {
      value: "YN SEO & Content marketing ",
      label: "YN SEO & Content marketing",
      name: "entity",
    },
  ];
  const squads = [
    { value: "Moon Walkers", label: "Moon Walkers", name: "squad" },
    { value: "Solar Unit", label: "Solar Unit", name: "squad" },
    { value: "Space Vikings", label: "Space Vikings", name: "squad" },
    { value: "Stars Explorers", label: "Stars Explorers", name: "squad" },
    { value: "The Astros", label: "The Astros", name: "squad" },
  ];
  const expertises = [
    { value: "All", label: "All", name: "expertise" },
    { value: "Content Factory", label: "Content Factory", name: "expertise" },
    {
      value: "Content marketing",
      label: "Content Marketing",
      name: "expertise",
    },
    { value: "Data", label: "Data", name: "expertise" },
    { value: "Design", label: "Design", name: "expertise" },
    { value: "Learning", label: "Learning", name: "expertise" },
    { value: "Media", label: "Media", name: "expertise" },
    { value: "R&D", label: "R&D", name: "expertise" },
    { value: "SEO", label: "SEO", name: "expertise" },

    { value: "Social media", label: "Social media", name: "expertise" },
  ];

  const role = [{ value: "employe", label: "Employe", name: "roles" }];

  const chooseActive = [
    { value: "true", label: "actif", name: "isActive" },
    { value: "false", label: "non actif", name: "isActive" },
  ];

  const options =
    entité &&
    entité.map((el) => {
      return {
        value: el.value,
        label: el.label,
        name: "name",
        id: el.id,
      };
    });

  const options2 =
    squads &&
    squads.map((el) => {
      return {
        value: el.value,
        label: el.label,
        name: "name",
        id: el.id,
      };
    });

  const options3 =
    role &&
    role.map((el) => {
      return {
        value: el.value,
        label: el.label,
        name: "name",
        id: el.id,
      };
    });

  const options4 =
    expertises &&
    expertises.map((el) => {
      return {
        value: el.value,
        label: el.label,
        name: "name",
        id: el.id,
      };
    });

  const handleSubmitEmploye = async (e) => {
    //e.preventDefault();
    const formData = new URLSearchParams();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    // formData.append("password", password);
    formData.append("entity", entity);
    formData.append("squad", squad);
    formData.append("roles", roles);
    formData.append("image", image);
    formData.append("expertise", expertise);
    //formData.append("yuriId", yuriId);
    formData.append("isActive", isActive);

    await instance
      .post(`/user/create`, formData)
      .then((res) => {
        toast.success("Employé crée avec succès", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        setError(null);
        setOpened(false);
      })
      .catch((err) => {
        console.log("err.response.data.status :>> ", err.response);
        if (err.response.status === 409) {
          toast.error("Cet email est déjà utilisé !", {
            position: toast.POSITION.TOP_LEFT,
          });
        } else {
          toast.error("Oups une erreur !", {
            position: toast.POSITION.TOP_LEFT,
          });
        }

        // setError(error.response.data.msg);
        console.log("err :>> ", err);
      });
  };

  if (dataToDisplay.length === 0 && !loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        Chargement en cours...
      </div>
    );
  }

  const handleShowInactiveChange = () => {
    setShowInactiveEmployees(!showInactiveEmployees);
  };

  // const exportToCSV = () => {
  //   const csvData = Papa.unparse(data);

  //   const blob = new Blob([csvData], { type: "text/csv" });
  //   const url = window.URL.createObjectURL(blob);

  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "employees.csv";
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   window.URL.revokeObjectURL(url);
  // };

  const exportToCSV = () => {
    // Collecter toutes les dates uniques de tous les employés
    const allDates = new Set();
    data.forEach((employee) => {
      Object.keys(employee).forEach((key) => {
        if (
          key !== "Nom" &&
          key !== "Total BDC" &&
          key !== "expertise" &&
          key !== "id" &&
          key !== "isActive"
        ) {
          allDates.add(key);
        }
      });
    });

    // Convertir l'ensemble de dates en tableau et trier les dates
    const sortedDates = Array.from(allDates).sort();

    // Créer les lignes du tableau CSV
    const csvRows = [];

    // Ajouter la première ligne avec les en-têtes
    const headers = ["Nom", "Total BDC", "expertise", "Actif", ...sortedDates];
    csvRows.push(headers.join(","));

    // Ajouter les lignes de données pour chaque employé
    data.forEach((employee) => {
      const rowData = [
        employee.Nom,
        employee["Total BDC"],
        employee.expertise,
        employee.isActive,
        ...sortedDates.map((date) => employee[date] || ""),
      ];
      csvRows.push(rowData.join(","));
    });

    // Générer le CSV
    const csvData = csvRows.join("\n");

    // Télécharger le fichier CSV
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "employees.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <div className="employes">
        <header>
          <div>
            <h1>Liste des employés</h1>
          </div>
        </header>
        <div style={{ marginLeft: "50px" }}>
          <NavView />
        </div>
        <div className="search-create">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              style={{ marginRight: "20px" }}
              checked={showInactiveEmployees}
              onChange={handleShowInactiveChange}
              label="Afficher les employés inactifs"
            />

            <SegmentedControl
              value={gape}
              onChange={setGape}
              data={[
                { label: "12", value: "12" },
                { label: "12 + 3", value: "15" },
                { label: "24", value: "24" },
                { label: "All", value: "all" },
              ]}
            />
            <button
              className="button-create-employee"
              style={{ marginLeft: "20px" }}
              onClick={exportToCSV}
            >
              Exporter en CSV
            </button>

            <button
              className="button-create-employee"
              style={{ marginLeft: "20px" }}
              onClick={() => setOpened(true)}
            >
              Créer employé
            </button>
          </div>
        </div>

        <Modal
          opened={opened}
          onClose={() => setOpened(false)}
          className="modal-employe"
          overlayOpacity={0.55}
          overlayBlur={3}
          transition="fade"
          transitionDuration={600}
          transitionTimingFunction="ease"
          size={900}
        >
          <form
            className="container-form"
            action=""
            method="post"
            onSubmit={handleSubmit(handleSubmitEmploye)}
          >
            <h2>Créer un nouvel employé</h2>
            <div className="container-label-input">
              <div className="label-input">
                <label htmlFor="lastName">
                  Nom <span>*</span>
                </label>
                <input
                  id="lastName"
                  type="name"
                  name="lastName"
                  {...register("lastName", {
                    required: "Required",
                  })}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <span className="warning-msg">
                  {errors.lastName && errors.lastName.message}
                </span>
              </div>
              <div className="label-input">
                <label htmlFor="firstName">
                  Prénom <span>*</span>
                </label>
                <input
                  id="firstName"
                  type="name"
                  name="firstName"
                  {...register("firstName", {
                    required: "Required",
                  })}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <span className="warning-msg">
                  {errors.firstName && errors.firstName.message}
                </span>
              </div>
            </div>
            <div className="container-label-input">
              <div className="label-input">
                <label htmlFor="email">
                  Email <span>*</span>
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  {...register("email", {
                    required: "Required",
                  })}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span className="warning-msg">
                  {errors.email && errors.email.message}
                </span>
              </div>
              <div className="label-input">
                <label htmlFor="firstName">
                  Employé actif ? <span>*</span>
                </label>
                <Select
                  w={325}
                  color="gray.0"
                  // backgroundColor="dark.8"

                  placeholder="Votre employé est toujours chez YN ?"
                  nothingFound="No options"
                  data={chooseActive}
                  onChange={setIsActive}
                  name="isActive"
                  id="isActive"
                />
                <span className="warning-msg">
                  {errors.isActive && errors.isActive.message}
                </span>
              </div>
            </div>

            <div className="container-label-input">
              <Select
                w={325}
                color="gray.0"
                style={{ marginTop: 20 }}
                // backgroundColor="dark.8"
                label="Brand"
                placeholder="Choisissez votre brand"
                searchable
                nothingFound="No options"
                data={options}
                onChange={setEntity}
                name="entity"
                id="entity"
                required
              />
              <Select
                w={325}
                color="gray.0"
                // backgroundColor="dark.8"
                style={{ marginTop: 20 }}
                label="Squad"
                placeholder="Choisissez la squad"
                searchable
                nothingFound="No options"
                data={options2}
                onChange={setSquad}
                name="squad"
                id="squad"
              />
            </div>
            <div className="container-label-input">
              <Select
                style={{ marginTop: 20 }}
                w={325}
                color="gray.0"
                // backgroundColor="dark.8"
                label="Expertise"
                placeholder="Choisissez le rôle"
                searchable
                nothingFound="No options"
                data={options4}
                onChange={setExpertise}
                name="expertise"
                id="expertise"
                required
              />

              <Select
                style={{ marginTop: 20 }}
                w={325}
                color="gray.0"
                // backgroundColor="dark.8"
                label="Rôle"
                placeholder="Choisissez le rôle"
                searchable
                nothingFound="No options"
                data={options3}
                onChange={setRoles}
                name="roles"
                id="roles"
                required
              />
            </div>
            <div className="container-label-input">
              <div className="label-input">
                <label>Photo</label>
                <input
                  style={{ cursor: "pointer", marginRight: "325px" }}
                  className="input-file"
                  type="file"
                />
              </div>

              <div>
                <div className="label-input"></div>
              </div>
            </div>

            <div className="container-button">
              <button
                style={{ cursor: "pointer" }}
                className="button"
                type="submit"
                // onClick={() => {
                //   setOpened(false);
                // }}
              >
                Créer employé
              </button>
            </div>
          </form>
        </Modal>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
              marginBottom: "5rem",
            }}
          >
            <LoaderComp />
          </div>
        ) : (
          <div className="container-table">
            <Styles>
              <div {...getTableProps()} className="table sticky">
                <div className="header">
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="tr">
                      {headerGroup.headers.map((column) => (
                        <div {...column.getHeaderProps()} className="th">
                          {column.render("Header")}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div {...getTableBodyProps()} className="body">
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        className="tr-table"
                        {...row.getRowProps()}
                        onClick={(event) => {
                          const cmdOrCtrlPressed =
                            event.metaKey || event.ctrlKey;
                          const { id } = row.original;
                          // Si la touche CMD/Ctrl est enfoncée, ignorer l'action de redirection
                          if (!cmdOrCtrlPressed) {
                            navigate(`/employes/bdcbyuser/${id}`);
                          }
                          handleRightClick(event, id);
                        }}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              <span className={getClass(cell.value)}>
                                {cell.render("Cell")}
                              </span>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </div>
              </div>
            </Styles>
            {isVisible && (
              <button className="scroll-to-top" onClick={scrollToTop}>
                ↑
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Employes;
