import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/logo1.svg";
import userimg from "../assets/user.jpg";
import { AuthContext } from "../auth/auth";
import { Menu, Text } from "@mantine/core";
import parametres from "../assets/Settings.svg";
import deconnexion from "../assets/logout.svg";

const Navbar = () => {
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);

  const Parametres = ({ size }) => {
    return (
      <div>
        <img src={parametres} alt="Parametres" width={size} height={size} />
      </div>
    );
  };

  const Deconnexion = ({ size }) => {
    return (
      <div>
        <img src={deconnexion} alt="Deconnexion" width={size} height={size} />
      </div>
    );
  };

  const logOut = () => {
    setAuthState({ ...authState, status: false });
    localStorage.clear();
    navigate("/");
  };
  return (
    <nav className="nav-bar">
      <section className="container-nav-bar">
        <div className="logo-title">
          <img
            src={logo}
            width={45}
            alt="logo"
            onClick={() => navigate("/employes")}
          />
          <span onClick={() => navigate("/employes")}>Cockpit Manager</span>
        </div>
        <div className="nav-links">
          <NavLink to={`/employes`}>Employés</NavLink>
          <NavLink to={`/bdc`}>Bon de commande</NavLink>
          <NavLink to={`/clients`}>Clients</NavLink>
        </div>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <div className="container-user">
              <div className="display-user">
                <div>
                  <img className="user-img" src={userimg} alt="user" />
                </div>

                <div className="name-email">
                  {authState.name && <span>{authState.name}</span>}
                  {authState.email && <p>{authState.email}</p>}
                </div>
              </div>
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item icon={<Parametres size={14} />}>Mes réglages</Menu.Item>

            <Menu.Divider />

            <Menu.Label>Danger zone</Menu.Label>

            <Menu.Item
              onClick={() => logOut()}
              color="red"
              icon={<Deconnexion size={14} />}
            >
              Déconnexion
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </section>
    </nav>
  );
};

export default Navbar;
