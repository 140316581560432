import React, { useContext, useState } from "react";
import { parse } from "papaparse";
import { Table, ScrollArea } from "@mantine/core";
import drop from "../assets/drop.svg";
import instance from "../axios/global";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import exemple from "../assets/Exemple.csv";
import { AuthContext } from "../auth/auth";
import LoaderComp from "../components/LoaderComp";
import ImportantErrorMessage from "../components/ImportantErrorMessage";
import important from "../assets/warning-filled.svg";

const ImportBDC = () => {
  const [parsedCsvData, setParsedCsvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const { authState } = useContext(AuthContext);
  const [loadingApiResponse, setLoadingApiResponse] = useState(false);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [importantError, setImportantError] = useState(null);
  const removeSpace = () => {
    parsedCsvData.forEach(function (e, i) {
      // Iterate over the keys of object
      Object.keys(e).forEach(function (key) {
        // Copy the value
        var val = e[key],
          newKey = key.replace(/\s+/g, "");

        // Remove key-value from object
        delete parsedCsvData[i][key];

        // Add value with new key
        parsedCsvData[i][newKey] = val;
      });
    });
  };
  const handleSubmitBdcData = async () => {
    if (parsedCsvData.length !== 0) {
      const formDataWithCSV = new FormData();
      const csvFile = file;
      formDataWithCSV.append("file", csvFile);
      formDataWithCSV.append("user", JSON.stringify(authState.id));
      const resWithCSV = await instance.post(
        "/bdc/file/withcsv",
        formDataWithCSV
      );
      if (resWithCSV.data.message === "") {
        console.log("resWithCSV :>> ", resWithCSV);
        throw new Error(
          "Erreur lors de la première requête avec le fichier CSV"
        );
      }

      const parsedCsvfile = parsedCsvData?.filter((el) => {
        return el["Nom Affaire"];
      });

      const parsedCsvForApi = parsedCsvfile.map((client, index) => {
        const DatedeDebut = moment(client["Date de Debut"], "DD/MM/YYYY");
        const DatedeFin = moment(client["Date de Fin"], "DD/MM/YYYY");

        // Vérifier si les dates sont valides

        const formattedDateDebut = DatedeDebut.format("YYYY-MM");
        const formattedDateFin = DatedeFin.format("YYYY-MM");
        if (
          !DatedeDebut.isValid() ||
          !DatedeFin.isValid() ||
          !DatedeDebut.isSameOrBefore(DatedeFin)
        ) {
          console.log("Invalid date found. Skipping this entry.");
          toast.error(
            `Date invalide pour le bdcs ${client["Numero Affaire"]} il sera ignoré`,
            {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            }
          );
          return null; // Ignore this entry
        }
        const myString = client["Total devis Client TTC"];
        const myNumber = Number(myString.replace(",", ".").replace(" ", ""));
        const myInteger = Math.floor(myNumber);

        const myString2 = client["Marge Estime"];
        const myNumber2 = Number(myString2.replace(",", ".").replace(" ", ""));
        const myInteger2 = Math.floor(myNumber2);
        return {
          client_name: client["Client"],
          name: client["Nom Affaire"],
          number: client["Numero Affaire"],
          accounting_code: client["Code Comptable"],
          type: client["Type"],
          start_date: formattedDateDebut,
          end_date: formattedDateFin,
          estimated_amount: myInteger2,
          amount_ttc: myInteger,
          userId: authState?.id,
        };
      });

      // Filtrer les entrées null (dates invalides) de parsedCsvForApi
      const validEntries = parsedCsvForApi.filter((entry) => entry !== null);

      const chunkSize = 10; // Taille de chaque morceau de données
      const chunkedData = [];

      // Divisez les données en morceaux
      for (let i = 0; i < validEntries.length; i += chunkSize) {
        const chunk = validEntries.slice(i, i + chunkSize);
        chunkedData.push(chunk);
      }

      setPostLoading(true);

      try {
        // Envoyez les morceaux de données un par un
        for (let i = 0; i < chunkedData.length; i++) {
          const res = await instance.post("/bdc/bulk", {
            data: chunkedData[i],
          });
        }

        setPostLoading(false);
        toast.success("Bons de commandes envoyés", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        navigate("/bdc");
      } catch (error) {
        setPostLoading(false);
        if (error?.response?.data?.errorBdcNumbers) {
          toast.error(
            `Erreur : Certains BDC avec attribution personnalisée ne sont pas compatibles avec l'importation automatique. Veuillez les retirer : ${error?.response?.data?.errorBdcNumbers}`,
            {
              position: toast.POSITION.TOP_LEFT,
            }
          );
        } else {
          toast.error("Error !", {
            position: toast.POSITION.TOP_LEFT,
          });
        }
        console.log("error :>> ", error);
      }
    } else {
      toast.error("No bdc to send !", {
        position: toast.POSITION.TOP_LEFT,
      });
      throw new Error("pas de bdc pas d'envoi");
    }
  };
  const disabledButtonClass = "disabled-button";
  const filteredCsvData = parsedCsvData.filter(
    (item) => item["Numero Affaire"] !== ""
  );
  const parsedCsvDataWithValidity = filteredCsvData.map((item) => {
    // Validation de base
    const totalDevisClientTTC = parseInt(item["Total devis Client TTC"]);
    const margeEstime = parseInt(item["Marge Estime"]);
    const basicValidation =
      item["Numero Affaire"] &&
      item["Date de Debut"] &&
      item["Date de Fin"] &&
      item["Nom Affaire"] &&
      !isNaN(totalDevisClientTTC) && // Vérifie si la valeur est un nombre
      !isNaN(margeEstime);
    // item.Client &&
    // item["Total devis Client TTC"] &&
    // item["Marge Estime"]; /* &&
    //  item["Type"]; */

    // Vérification de la date de début et de la date de fin
    const DatedeDebut = moment(item["Date de Debut"], "DD/MM/YYYY");
    const DatedeFin = moment(item["Date de Fin"], "DD/MM/YYYY");
    const isValidDate = DatedeDebut.isValid() && DatedeFin.isValid();

    const dateValidation = isValidDate && DatedeDebut.isSameOrBefore(DatedeFin);

    // Vérification finale de la validité
    const isValid = basicValidation && dateValidation;

    return {
      ...item,
      isValid: isValid,
    };
  });
  // const getErrorType = (item) => {
  const getErrorType = (item) => {
    if (!item.isValid) {
      const errors = [];
      if (!item["Numero Affaire"]) errors.push("Numero d'affaire manquante");
      if (!item["Date de Debut"]) errors.push("Date de début manquante");
      if (!item["Date de Fin"]) errors.push("Date de fin manquante");
      if (!item["Nom Affaire"]) errors.push("Nom du BDC manquant");
      if (!item["Client"]) errors.push("Client manquant");

      // Vérification des valeurs en tant qu'entiers
      const totalDevisClientTTC = parseInt(item["Total devis Client TTC"]);
      const margeEstime = parseInt(item["Marge Estime"]);

      if (isNaN(totalDevisClientTTC))
        errors.push("Le Total devis Client TTC doit être un nombre");
      if (isNaN(margeEstime))
        errors.push("La Marge Estime doit être un nombre");

      // Vérification de la cohérence des dates
      if (item["Date de Debut"] && item["Date de Fin"]) {
        const DatedeDebut = moment(item["Date de Debut"], "DD/MM/YYYY");
        const DatedeFin = moment(item["Date de Fin"], "DD/MM/YYYY");
        if (DatedeDebut.isAfter(DatedeFin)) {
          errors.push("La date de fin est antérieure à la date de début");
        }
      }

      if (errors.length > 0) {
        return `${errors.join(" | ")}`;
      }
    }
    return "Aucune erreur";
  };

  //   if (!item.isValid) {
  //     const errors = [];
  //     if (!item["Numero Affaire"]) errors.push("Numero d'affaire manquante");
  //     if (!item["Date de Debut"]) errors.push("Date de début manquante");
  //     if (!item["Date de Fin"]) errors.push("Date de fin manquante");
  //     if (!item["Nom Affaire"]) errors.push("Nom du BDC manquant");
  //     if (!item["Client"]) errors.push("Client manquant");
  //     if (!item["Total devis Client TTC"]) errors.push("Valeur TTC manquante");
  //     if (!item["Marge Estime"]) errors.push("Marge estimée manquante");
  //     // if (!item["Type"]) errors.push("Type manquant");
  //     if (item["Date de Debut"] && item["Date de Fin"]) {
  //       const DatedeDebut = moment(item["Date de Debut"], "DD/MM/YYYY");
  //       const DatedeFin = moment(item["Date de Fin"], "DD/MM/YYYY");
  //       if (DatedeDebut.isAfter(DatedeFin)) {
  //         errors.push("La date de fin est < que la date de début");
  //       }
  //     }
  //     if (errors.length > 0) {
  //       return ` ${errors.join(", ")}`;
  //     }
  //   }
  //   return "Aucune erreur";
  // };
  return (
    <>
      <section className="importBDC-page">
        <h1>Import BDC</h1>
        {postLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "8rem",
            }}
          >
            <LoaderComp size="lg" />
          </div>
        ) : (
          <div
            className="ondrop-div"
            onDragOver={(event) => {
              event.preventDefault();
            }}
            onDrop={(event) => {
              event.preventDefault();
              setLoading(true);
              setImportantError(null);
              setParsedCsvData([]);
              Array.from(event.dataTransfer.files).map(async (file) => {
                setFile(file);
                let text = await file.text();
                let match = text.match(/[,;\t]/); // Vous pouvez ajouter d'autres caractères si nécessaire

                let delimiter = ",";
                if (match) {
                  delimiter = match[0];
                }
                let resultIndex = parse(text);
                let result = parse(text, {
                  header: true,
                  delimiter: delimiter,
                });

                const errors = [];

                // Check if the number of columns is equal to 1
                if (resultIndex.data[0].length === 1) {
                  errors.push(
                    <span>
                      Votre fichier n'est pas correctement structuré : merci de
                      récupérer le fichier exemple{" "}
                      <a href={exemple} download>
                        Fichier
                      </a>
                    </span>
                  );
                  setImportantError(errors);
                  setLoading(false);
                  return;
                }

                const expectedValues = [
                  { index: 0, value: "Numero Affaire" },
                  { index: 1, value: "Nom Affaire" },
                  { index: 2, value: "Type" },
                  { index: 3, value: "Client" },
                  { index: 4, value: "Code Comptable" },
                  { index: 9, value: "Date de Debut" },
                  { index: 10, value: "Date de Fin" },
                  { index: 11, value: "Total devis Client TTC" },
                  { index: 18, value: "Marge Estime" },
                ];

                // Check for header errors
                expectedValues.forEach(({ index, value }) => {
                  if (resultIndex.data[0][index] !== value) {
                    errors.push(
                      `Colonne ${
                        index + 1
                      }: Le nom de l'en-tête doit être "${value}"`
                    );
                  }
                });

                if (errors.length > 0) {
                  setImportantError(errors);
                  setLoading(false);
                  return;
                } else {
                  setParsedCsvData(
                    result?.data?.filter(
                      (item) => item["Numero Affaire"] !== ""
                    )
                  );
                }
                setLoading(false);
              });
            }}
          >
            {loading ? (
              <div className="loader">Chargement...</div>
            ) : (
              <>
                <img src={drop} alt="drop" />
                <p>Drag and drop votre fichier csv ou </p>
                <input
                  type="file"
                  className="input-import"
                  id="file-input"
                  accept=".csv,.txt"
                  onChange={(event) => {
                    event.preventDefault();
                    const files = event.target.files;
                    console.log("change");
                    if (files && files.length > 0) {
                      setLoading(true);
                      setImportantError(null);
                      setParsedCsvData([]);
                      Array.from(files).map(async (file) => {
                        setFile(file);
                        let text = await file.text();
                        let resultIndex = parse(text);
                        let result = parse(text, { header: true });
                        const expectedValues = [
                          { index: 0, value: "Numero Affaire" },
                          { index: 1, value: "Nom Affaire" },
                          { index: 2, value: "Type" },
                          { index: 3, value: "Client" },
                          { index: 4, value: "Code Comptable" },
                          { index: 9, value: "Date de Debut" },
                          { index: 10, value: "Date de Fin" },
                          { index: 11, value: "Total devis Client TTC" },
                          { index: 18, value: "Marge Estime" },
                        ];
                        const errors = [];
                        if (resultIndex.data[0].length === 1) {
                          errors.push(
                            <span>
                              Votre fichier n'est pas correctement structuré :
                              merci de récupérer le fichier exemple{" "}
                              <a href={exemple} download>
                                Fichier
                              </a>
                            </span>
                          );
                          setImportantError(errors);
                          setLoading(false);
                          return;
                        }
                        expectedValues.forEach(({ index, value }) => {
                          if (resultIndex.data[0][index] !== value) {
                            errors.push(
                              // `La valeur attendue pour l'index ${index} est "${value}", mais la valeur trouvée est "${resultIndex.data[0][index]}"`
                              `Colonne ${
                                index + 1
                              } : Le nom de l'en-tête doit être "${value}"`
                            );
                          }
                        });
                        if (errors.length > 0) {
                          setImportantError(errors);
                          setLoading(false);
                          return;
                        } else {
                          setParsedCsvData(
                            result?.data?.filter(
                              (item) => item["Numero Affaire"] !== ""
                            )
                          );
                        }

                        setLoading(false);
                      });
                    }
                  }}
                />
              </>
            )}
          </div>
        )}
        <div className="exemple-link">
          <a href={exemple} download>
            Exemple de fichier à envoyer
          </a>
        </div>
        {importantError ? (
          <ImportantErrorMessage message={importantError} comp={"importbdc"} />
        ) : (
          ""
        )}
        {importantError || parsedCsvData.length > 0 ? (
          ""
        ) : (
          <div
            style={{
              margin: "-50px 50px 0px 50px",
            }}
            className="container-warning-msg"
          >
            <div className="logo-and-title" style={{ display: "flex" }}>
              <img src={important} alt="important" />
              <h3 style={{ color: "red" }}>Important</h3>
            </div>
            <p style={{ color: "red" }}>
              - Merci d'uploader votre fichier Manager sans modifier les entêtes
            </p>
            <p style={{ color: "red" }}>
              - Si vous modifier les valeurs du tableau, merci de conserver le
              même format de données
            </p>
          </div>
        )}

        <ScrollArea>
          {parsedCsvData.length > 0 && (
            <div>
              {parsedCsvDataWithValidity.every((item) => item.isValid) ? (
                <div className="succes-import-element">
                  <p className="valid-message">
                    Nous n'avons détecté aucune erreur. Vous pouvez envoyer les
                    BDCs.
                  </p>
                  <div className="div-button-import">
                    <button
                      className={
                        parsedCsvDataWithValidity.length === 0 ||
                        parsedCsvDataWithValidity.some((item) => !item.isValid)
                          ? disabledButtonClass
                          : ""
                      }
                      disabled={
                        parsedCsvDataWithValidity.length === 0 ||
                        parsedCsvDataWithValidity.some((item) => !item.isValid)
                      }
                      type="submit"
                      onClick={handleSubmitBdcData}
                    >
                      Envoyer les BDCS
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <h5
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      marginTop: "2rem",
                    }}
                  >
                    ❌ Nous ne pouvons pas importer vos BDCs, merci de corriger
                    ces erreurs :
                  </h5>
                  <table className="table">
                    <thead>
                      <tr className="table-tr">
                        <th>Numero Affaire</th>
                        {/* <th>Nom Affaire</th> */}
                        <th>Erreurs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {parsedCsvDataWithValidity
                        .filter((item) => !item.isValid)
                        .map((item, index) => (
                          <tr key={index}>
                            <td>{item["Numero Affaire"]}</td>
                            {/* <td>{item["Nom Affaire"]}</td> */}
                            <td>{getErrorType(item)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </ScrollArea>
      </section>
    </>
  );
};

export default ImportBDC;
