import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import instance from "../axios/global";
import { toast } from "react-toastify";
import { Modal, useMantineTheme } from "@mantine/core";

const CreateClient = () => {
  const [opened, setOpened] = useState(false);
  const [name, setName] = useState("");
  const [accountingCode, setAccountingCode] = useState();
  const [refresh, setRefresh] = useState(false);
  const [allClients, setAllClients] = useState([]);

  const clientSchema = yup.object().shape({
    name: yup.string().required("Veuillez renseigner le nom du nouveau client"),
    accounting_code: yup
      .string()
      .min(3, "Le code comptable doit comporter au moins 3 caractères")
      .max(15, "Le code comptable ne doit pas exceder 15 caractères")
      .required("Veuillez renseigner le code comptable"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(clientSchema),
  });

  const handleSubmitClient = async (e) => {
    //e.preventDefault();
    const formData = new URLSearchParams();
    formData.append("name", name);
    formData.append("accounting_code", accountingCode);

    await instance
      .post(`/client/create`, formData)
      .then((res) => {
        toast.success("Votre client a bien été crée.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        setRefresh(!refresh);
        setOpened(false);
      })
      .catch((error) => {
        toast.error(error.response.data.error, {
          position: toast.POSITION.TOP_LEFT,
        });
        console.log("err :>> ", error);
      });
  };

  const theme = useMantineTheme();

  return (
    <div>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        overlayOpacity={0.55}
        overlayBlur={3}
        transition="fade"
        transitionDuration={600}
        transitionTimingFunction="ease"
        size={650}
        padding={50}
      >
        <div className="modal-create-client">
          <form
            action=""
            method="post"
            onSubmit={handleSubmit(handleSubmitClient)}
          >
            <h2>Creer un nouveau client</h2>
            <h3>
              Lors de l’ajout d’un nouveau bon de commande, si votre client
              n’existe pas, il sera automatiquement crée *
            </h3>
            <div className="label-input">
              <label htmlFor="name" style={{ marginLeft: "15px" }}>
                Nom du client <span>*</span>
              </label>
              <input
                id="name"
                type="name"
                name="name"
                {...register("name", {
                  required: "Required",
                })}
                onChange={(e) => setName(e.target.value)}
              />
              <span className="warning-msg">
                {errors.name && errors.name.message}
              </span>
            </div>
            <div className="label-input">
              <label htmlFor="accounting_code">
                Code comptable <span>*</span>
              </label>
              <input
                id="accounting_code"
                type="accounting_code"
                name="accounting_code"
                {...register("accounting_code", {
                  required: "Required",
                })}
                onChange={(e) => setAccountingCode(e.target.value)}
              />
              <span className="warning-msg">
                {errors.accounting_code && errors.accounting_code.message}
              </span>
            </div>
            <div className="div-create-client">
              <button type="submit" className="btn-create-client">
                Créer un nouveau client
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <button className="btn-create-client" onClick={() => setOpened(true)}>
        Creer un nouveau client
      </button>
    </div>
  );
};

export default CreateClient;
