import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useBdcContext from "../hooks/useBdcContext";
import "dayjs/locale/fr";
import { DatePicker } from "@mantine/dates";
import instance from "../axios/global";
import * as moment from "moment";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Table, Modal, useMantineTheme } from "@mantine/core";
import { toast } from "react-toastify";
import { differenceInCalendarMonths } from "date-fns";
import { Tooltip } from "react-tooltip";
import question from "../assets/Question.svg";
import euro from "../assets/euro.svg";
import close from "../assets/delete.svg";
import trash from "../assets/Trash-2.svg";
import { AuthContext } from "../auth/auth";

// import * as yup from "yup";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";

const BdcToCheck = () => {
  const { authState } = useContext(AuthContext);
  const id = useParams();
  const [userId, setUserId] = useState("");
  const [opened, setOpened] = useState(false);
  const [opened2, setOpened2] = useState(false);
  const [lastValues, setLastValues] = useState();
  const [name, setName] = useState("");
  const [totalBdcAmount, setTotalBdcAmount] = useState();
  const [refresh, setRefresh] = useState(false);
  const [percentageByUser, setPercentageByUser] = useState(null);
  const { purchaseToAssign, setSplitByEmployee, splitByEmployee } =
    useBdcContext();
  const [numberOfMonth, setNumberOfMonth] = useState(0);
  const [consultantList, setConsultantList] = useState([]);
  const [allConsultantsAssigned, setAllConsultantsAssigned] = useState([]);
  // const [valueConsultant, setValueConsultant] = useState([]);
  // const [valueExpertise, setValueExpertise] = useState();
  // const [numberOfEmployee, setNumberOfEmployee] = useState();
  const [consultant, setConsultant] = useState([
    {
      id: "",
      expertise: "",
      percentage: 0,
      attribution: "",
    },
  ]);
  console.log(
    "🚀 ~ file: BdcToCheck.js:48 ~ BdcToCheck ~ consultant:",
    consultant
  );

  const theme = useMantineTheme();

  const {
    startDate,
    endDate,
    bdcNumber,
    bdcName,
    setBdcName,
    accountingCode,
    bdcAmount,
    attributionSchema,
    estimatedMargin,
    setEstimatedMargin,
    clientId,
    setClientId,
    clientName,
    setClientName,
    setBdcNumber,
    setAccountingCode,
    setStartDate,
    setEndDate,
    setPurchaseToAssign,
    setBdcAmount,
    setAttributionSchema,
    finalAmount,
    initialAmount,
    setFinalAmount,
    setInitialAmount,
  } = useBdcContext();

  const [percentages, setPercentages] = useState([]);
  const [displayAmount, setDisplayAmount] = useState(initialAmount);
  const navigate = useNavigate();

  //const [image, setImage] = useState();
  useEffect(() => {
    const fetchBDC = async () => {
      try {
        const res = await instance.get(`/bdc/double/${id.id}`);

        setAllConsultantsAssigned(res.data.realBdc.user);

        setLastValues(res.data.realBdc);
        setClientName(res.data.bdcWithNewValue.client_name);
        setBdcNumber(res.data.bdcWithNewValue.number);
        setAccountingCode(res.data.bdcWithNewValue.accounting_code);
        setStartDate(res.data.bdcWithNewValue.start_date);
        setEndDate(res.data.bdcWithNewValue.end_date);
        setBdcAmount(res.data.bdcWithNewValue.amount_ttc);
        setInitialAmount(
          parseInt(res?.data?.bdcWithNewValue.estimated_amount) || 0
        );
        setTotalBdcAmount(res?.data?.bdcWithNewValue.amount_ttc);
        setClientId(res?.data.bdcWithNewValue.clientId);
        setEstimatedMargin(res?.data.bdcWithNewValue.estimated_amount);
        setBdcName(res?.data.bdcWithNewValue.name);
      } catch (error) {
        console.log("🚀 ~ file: Step3.js:13 ~ fetchConsultant ~ error", error);
      }
    };
    fetchBDC();

    return () => {
      setClientName();
      setBdcNumber();
      setAccountingCode();
      setInitialAmount();
      setTotalBdcAmount();
      setEstimatedMargin();
      setBdcName();
    };
  }, []);

  function updateAmounts(newPercentages) {
    setPercentages(newPercentages);
    const updatedAmount = newPercentages.reduce((total, percentage) => {
      return total - (percentage * estimatedMargin) / 100;
    }, estimatedMargin);
    setFinalAmount(updatedAmount);
  }
  function handlePercentageChange(index, value) {
    const newPercentages = [...percentages];
    newPercentages[index] = value;
    updateAmounts(newPercentages);
  }

  const handleSubmitBdcUpdateData = async (e) => {
    const now = moment(startDate).format("YYYY-MM");
    const now2 = moment(endDate).format("YYYY-MM");
    if (e) {
      e.preventDefault(); // Appeler preventDefault() uniquement si l'objet d'événement est défini
    }
    // e.preventDefault();
    //console.log("yoyoyoyoyoyo");
    try {
      const res = await instance.put(`/bdc/update/doublon/${id.id}`, {
        number: bdcNumber,
        clientName: clientName,
        accounting_code: accountingCode,
        start_date: now,
        end_date: now2,
        estimated_margin: estimatedMargin,
        consultants: consultant,
        clientId: clientId,
        numberOfMonth: numMonths,
        amount_ttc: bdcAmount,
        bdcName: bdcName,
        goodToDelete: true,
      });
      navigate("/bdc");
      toast.success("Votre bon de commande a bien été modifié.", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    } catch (error) {
      toast.error(error.response.data.msg, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      console.log(
        "🚀 ~ file: CreationBDC.js:24 ~ handleSubmitBdcData ~ error",
        error
      );
    }
  };
  useEffect(() => {
    return () => {
      setFinalAmount();
      setBdcAmount();
    };
  }, [setBdcAmount, setFinalAmount]);

  useEffect(() => {
    const fetchConsultant = async () => {
      try {
        const res = await instance.get(`/user/all?role=${authState.role}`);
        setConsultantList(res.data);
      } catch (error) {
        console.log("🚀 ~ file: Step3.js:13 ~ fetchConsultant ~ error", error);
      }
    };
    fetchConsultant();
  }, [authState.role]);

  const handleAddMembers = () => {
    setConsultant([
      ...consultant,
      { id: "", montant: "", expertise: "", attribution: "" },
    ]);
  };
  const handleExpertise = (obj, index) => {
    const { value, name } = obj;
    console.log("obj :>> ", obj);
    const list = [...consultant];
    list[index][name] = value;

    setConsultant(list);
  };
  const handleBdcValue = (e, index) => {
    const { value } = e.target;
    const list = [...consultant];
    list[index]["montant"] = value;
    setConsultant(list);
  };
  const handleAttribution = (e, index) => {
    const { value } = e.target;
    const list = [...consultant];
    list[index]["attribution"] = value;
    setConsultant(list);
  };

  const handleRemoveConsultant = (index) => {
    const values = [...consultant];
    values.splice(index, 1);
    setConsultant(values);
  };

  const handleConsultant = (obj, index) => {
    const { value, id } = obj;
    console.log("obj :>> ", obj);
    const list = [...consultant];
    list[index][name] = value;
    console.log('list[index]["id"] = id; :>> ', (list[index]["id"] = value));
    setConsultant(list);
    // console.log("member :>> ", member);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,

      //borderBottom: "1px dotted #373a40",
      color: state.isSelected ? "#0A4BFF" : "black",
      padding: 20,

      background: "#ffffff",
      fontSize: "15px",
      "&:hover": {
        background: "#F0F3F8",
      },
    }),

    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),

    indicatorSeparator: () => ({
      background: "none",
    }),

    indicatorsContainer: () => ({
      // borderLeft: "1px solid #25262b",
      marginRight: "2px",
      cursor: "pointer",
    }),
    placeholder: (base) => ({
      ...base,
      height: "36px",
      color: "#6B6B6C",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      height: 36,
      width: 326,
      backgroundColor: "#FFFFFF",
      border: "1px solid #E3E3E3",
      borderRadius: "4px",
      fontSize: "14px",
      paddingLeft: 12,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      // background: "#25262b",
    }),
  };

  const expertise = [
    { value: "All", label: "All", name: "expertise" },
    { value: "Content Factory", label: "Content Factory", name: "expertise" },
    {
      value: "Content marketing",
      label: "Content Marketing",
      name: "expertise",
    },
    { value: "Data", label: "Data", name: "expertise" },
    { value: "Design", label: "Design", name: "expertise" },
    { value: "Learning", label: "Learning", name: "expertise" },
    { value: "Media", label: "Media", name: "expertise" },
    { value: "R&D", label: "R&D", name: "expertise" },
    { value: "SEO", label: "SEO", name: "expertise" },

    { value: "Social media", label: "Social media", name: "expertise" },
  ];

  useEffect(() => {
    const percentageValues = consultant.map((consultant) => consultant.montant);
    setPercentages(percentageValues);
  }, [consultant]);
  // console.log("startDate :>> ", startDate);
  // console.log("endDate :>> ", endDate);
  // console.log("intialAmount :>> ", initialAmount);
  // console.log("totalBdcAmount :>> ", totalBdcAmount);
  // console.log("estimedMargin :>> ", estimatedMargin);

  const numMonths =
    differenceInCalendarMonths(new Date(endDate), new Date(startDate)) + 1;

  useEffect(() => {
    if (finalAmount === 0) {
      setDisplayAmount(0);
    } else {
      setDisplayAmount(finalAmount || initialAmount);
    }
  }, [finalAmount, initialAmount]);

  const isConsultantListEmpty =
    !allConsultantsAssigned || allConsultantsAssigned.length === 0;

  const isInputDisabled = !isConsultantListEmpty;
  // const bdcSchema = yup.object().shape({
  //   number: yup.number().required("Veuillez renseigner le numéro du bon bdc"),
  //   name: yup.string().required("Veuillez renseigner le nom du bdc"),
  //   //clientName: yup.string().required("Veuillez choisir un client"),
  //   accounting_code: yup
  //     .string()
  //     .required("Veuillez renseigner le code comptable"),
  //   estimated_amount: yup
  //     .number("Le total TTC doit être un nombre")
  //     .required("Veuillez renseigner la valeur TTC du bdc"),
  //   amount_ttc: yup
  //     .number("La marge estimée doit être un nombre")
  //     .required("Veuillez renseigner la marge estimée du bdc"),
  // });

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(bdcSchema),
  // });
  const handleDeleteUserFromBdc = async (consultantId) => {
    try {
      const res = await instance.delete(
        `/user/delete/bdc/${consultantId}/${id.id}`
      );
      setOpened(false);

      toast.success(`L'attribution de ce consultant a bien été supprimée. `, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      const updatedConsultants = allConsultantsAssigned.filter(
        (consultant) => consultant.id !== consultantId
      );
      setAllConsultantsAssigned(updatedConsultants);
    } catch (error) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_LEFT,
      });
      console.log("error :>> ", error);
    }
  };

  return (
    <>
      <header className="header-create-BDC">
        <h1>Bon de commande a modifier</h1>
      </header>
      {/* 'STEP 1' */}
      <form action="" onSubmit={handleSubmitBdcUpdateData}>
        <main className="container-create-BDC">
          <section className="creationBDC-client">
            <h5>{clientName}</h5>
            <h6>{bdcName}</h6>
          </section>

          {/* 'STEP 2' */}
          <section className="creationBDC-bdc">
            <h2>Informations du bon de commande</h2>
            <h3>
              Toutes les informations importantes concernant votre bon de
              commande.
            </h3>
            <div className="container-label-input">
              <div className="label-input">
                <label htmlFor="">Numéro</label>
                <input
                  type="text"
                  defaultValue={bdcNumber}
                  placeholder="Entrez le numéro de votre bon de commande"
                  // {...register("number", {
                  //   required: "Required",
                  // })}
                  onChange={(e) => {
                    setBdcNumber(e.target.value);
                  }}
                />
                <span className="span-lastValue">
                  Ancienne valeur : {lastValues?.number}
                </span>
                {/* <span className="warning-msg">
                  {errors.number && errors.number.message}
                </span> */}
              </div>
              <div className="label-input-2">
                <label htmlFor="">Nom</label>
                <input
                  type="text"
                  value={bdcName}
                  placeholder="Entrez le nom de votre bdc"
                  onChange={(e) => {
                    setBdcName(e.target.value);
                  }}
                  // {...register("name", {
                  //   required: "Required",
                  // })}
                />
                <span className="span-lastValue">
                  Ancienne valeur : {lastValues?.name}
                </span>
                {/* <span className="warning-msg">
                  {errors.name && errors.name.message}
                </span> */}
              </div>
            </div>
            <div className="container-date-picker">
              <div>
                <DatePicker
                  locale="fr"
                  style={{ marginTop: 20 }}
                  label="Date de début"
                  placeholder=""
                  value={new Date(startDate)}
                  //classNames={classes}
                  // clearable={true}
                  //value={startDate}
                  onChange={setStartDate}
                  disabled={isInputDisabled}
                  required
                />
                <span className="span-lastValue">
                  Ancienne valeur : {lastValues?.start_date}
                </span>
              </div>
              <div>
                <DatePicker
                  locale="fr"
                  style={{ marginTop: 20, marginLeft: 80 }}
                  value={new Date(endDate)}
                  label="Date de fin"
                  placeholder=""
                  //classNames={classes}
                  // clearable={false}
                  //value={endDate}
                  onChange={setEndDate}
                  disabled={isInputDisabled}
                  required
                />

                <span className="span-lastValue" style={{ marginLeft: "80px" }}>
                  Ancienne valeur : {lastValues?.end_date}
                </span>
              </div>
            </div>
            <div className="container-label-input">
              <div className="label-input">
                <label>Code comptable</label>
                <input
                  id="accounting_code"
                  type="accounting_code"
                  name="accounting_code"
                  defaultValue={accountingCode}
                  placeholder="Entrez votre code comptable"
                  // {...register("accounting_code", {
                  //   required: "Required",
                  // })}
                  onChange={(e) => {
                    setAccountingCode(e.target.value);
                  }}
                />
                <span className="span-lastValue">
                  Ancienne valeur : {lastValues?.accounting_code}
                </span>
                {/* <span className="warning-msg">
                  {errors.accounting_code && errors.accounting_code.message}
                </span> */}
              </div>
              <div className="label-input-2">
                <label htmlFor="">Valeur TTC</label>
                <input
                  id="valeurBDC"
                  type="number"
                  placeholder="Entrez le montant de votre BDC"
                  min={0}
                  value={bdcAmount}
                  // {...register("estimated_amount")}
                  onChange={(e) => {
                    setBdcAmount(e.target.value);
                    setInitialAmount(e.target.value);
                  }}
                />
                <span className="span-lastValue">
                  Ancienne valeur : {lastValues?.amount_ttc}
                </span>
                {/* <span className="warning-msg">
                  {errors.estimated_amount && errors.estimated_amount.message}
                </span> */}
              </div>
            </div>
            <div className="container-label-input">
              <div className="label-input">
                <label htmlFor="">Marge estimée</label>
                <input
                  id="margeBDC"
                  type="text"
                  placeholder="Entrez le montant de votre BDC"
                  onChange={(e) => {
                    setEstimatedMargin(e.target.value);
                  }}
                  value={estimatedMargin}
                  disabled={isInputDisabled}
                />
                <span className="span-lastValue">
                  Ancienne valeur : {lastValues?.estimated_amount}
                </span>
                {/* <span className="warning-msg">
                  {errors.amount_ttc && errors.amount_ttc.message}
                </span> */}
              </div>
            </div>
          </section>
          <section className="creationBDC-attribution">
            <h2>Attribution du bon de commande</h2>
            <h3>
              Attribuez un ou plusieurs consultants et expertise a votre bon de
              commande.
            </h3>
            <div className="select-total">
              <div className="total-BDC">
                <div className="div-total-euro">
                  <p>Total du bon de commande à attribuer : </p>
                  <img src={euro} alt="€" />
                </div>

                <span>{displayAmount}€</span>
              </div>
              <div className="all-consultants-assigned">
                <Modal
                  overlayOpacity={0.55}
                  overlayBlur={3}
                  transition="fade"
                  transitionDuration={600}
                  transitionTimingFunction="ease"
                  size={650}
                  padding={50}
                  opened={opened}
                  onClose={() => setOpened(false)}
                  className="modal-remove"
                >
                  <div className="container-confirm-delete">
                    <p>
                      Etes-vous sûr de vouloir supprimer ce consultant de votre
                      bon de commande ?
                    </p>
                    <span>
                      Vous ne pourrez plus revenir en arrière. Toutes les
                      anciennes attributions du consultant seront perdus.
                    </span>
                    <div className="div-button-choice">
                      <button
                        className="btn-no"
                        onClick={() => setOpened(false)}
                      >
                        Annuler
                      </button>
                      <button
                        className="btn-yes"
                        onClick={() => handleDeleteUserFromBdc(userId)}
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                </Modal>
                {allConsultantsAssigned?.length > 0 ? (
                  <table className="table-consultants-assigned">
                    <thead>
                      <tr>
                        <th style={{ paddingLeft: "3rem" }}>Nom</th>
                        <th>Expertise</th>
                        <th>Attribution</th>
                        <th>Pourcentage</th>
                        <th></th>
                      </tr>
                    </thead>
                    {allConsultantsAssigned &&
                      allConsultantsAssigned?.map((consultant) => (
                        <tbody key={consultant.id}>
                          <tr>
                            <td style={{ paddingLeft: "3rem" }}>
                              {consultant.firstName} {consultant.lastName}
                            </td>
                            {consultant.Bdc_User && (
                              <>
                                <td>{consultant.Bdc_User.userRole}</td>
                                <td>{consultant.Bdc_User.attribution}</td>

                                <td>
                                  {consultant.Bdc_User.percentageOfAttribution}%
                                </td>
                                <td>
                                  <img
                                    src={trash}
                                    alt="remove"
                                    className="remove-consultant-attribution"
                                    onClick={() => {
                                      setOpened(true);
                                      setUserId(consultant.id);
                                    }}
                                  />
                                </td>
                              </>
                            )}
                          </tr>
                        </tbody>
                      ))}
                  </table>
                ) : (
                  ""
                )}
              </div>
              <div>
                {isConsultantListEmpty ? (
                  ""
                ) : (
                  <p className="edit-msg">
                    Pour pouvoir éditer la date ou la marge estimée du bon de
                    commande, merci de supprimer vos attributions.
                  </p>
                )}
              </div>
              {consultant.map((el, index) => {
                const option =
                  consultantList &&
                  consultantList
                    .map((el) => {
                      return {
                        value: el.id,
                        label: el.lastName + " " + el.firstName,
                        name: "name",
                      };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));
                return (
                  <div key={index}>
                    {index === 0 ? (
                      ""
                    ) : (
                      <div className="div-img-delete">
                        <img
                          src={close}
                          alt="close"
                          className="img-delete"
                          onClick={handleRemoveConsultant}
                        />
                      </div>
                    )}
                    <div className="select-input">
                      <Select
                        styles={customStyles}
                        label="Consultants"
                        placeholder="Ajoutez un consultant"
                        searchable
                        nothingFound="No options"
                        options={option}
                        onChange={(obj) => handleConsultant(obj, index)}
                      />
                      <div className="label-input-2">
                        <label htmlFor="">Pourcentage</label>
                        <input
                          id="montantConsultant"
                          type="number"
                          min={0}
                          max={100}
                          placeholder="Entrez le montant du consultant"
                          onBlur={(event) => {
                            let value = event.target.value;
                            if (value > 100) {
                              value = 100;
                              event.target.value = value; // réinitialisation de la valeur de l'input
                            } else if (value < 0) {
                              value = 0;
                              event.target.value = value; // réinitialisation de la valeur de l'input
                            }
                          }}
                          onChange={(event) => {
                            // setPercentageByUser(event.target.value);
                            handleBdcValue(event, index);
                            handlePercentageChange(index, event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="select-input">
                      <Select
                        styles={customStyles}
                        style={{ marginTop: 20, color: "blue" }}
                        label="Expertise"
                        placeholder="Ajoutez l'expertise du consultant"
                        searchable
                        nothingFound="No options"
                        options={expertise}
                        onChange={(obj) => handleExpertise(obj, index)}
                      />
                      <div className="container-label-input">
                        <div className="label-input-2">
                          <label
                            htmlFor=""
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* <ReactTooltip
                              type="light"
                              id="my-tooltip"
                              data-tooltip-content="salut la famille"
                            > */}
                            Schema d'attribution - Nombre de mois : {numMonths}
                            <p
                              data-tooltip-id="my-tooltip-multiline"
                              data-tooltip-html="En mettant 'renew': La marge sera répartie de manière égale chaque mois. <br />
En mettant 'newbiz' : La marge sera de 20% le 1er mois puis répartie de manière égale chaque mois.<br />
Modèle personnalisé : Pour attribuer la marge de manière personnalisée,<br /> vous devez indiquer le pourcentage pour chaque mois, en les séparant par une virgule. <br />Si un mois n'a pas de marge, vous devez mettre 0. <br />Exemple pour 3 mois à 10%, 20% et 70% : 10%,20%,70%"
                            >
                              <img src={question} alt="tooltip" />
                            </p>
                            <Tooltip
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                              id="my-tooltip-multiline"
                            ></Tooltip>
                          </label>
                          <input
                            id="valeurBDC"
                            onChange={(e) => {
                              setAttributionSchema(e.target.value);
                              handleAttribution(e, index);
                            }}
                            type="text"
                            placeholder="Spliter le bdc ex 20%,10%,30%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="div-add-another">
                <span className="span-add-another" onClick={handleAddMembers}>
                  + Ajouter un consultant
                </span>
              </div>
            </div>
          </section>
          {/* <section className="creationBDC-autre">
            <h2>3- Autre</h2>
            <div className="label-input">
              <label htmlFor="">Dépenses supplémentaires ?</label>
              <input
                type="text"
                placeholder="Entrez les dépenses supplémentaires"
              />
            </div>
          </section> */}

          <button className="btn-create-bdc">Modifier BDC</button>
        </main>
      </form>
    </>
  );
};

export default BdcToCheck;
