import React, { useState, useEffect, useContext } from "react";
import { TextInput, PasswordInput, Checkbox, Button } from "@mantine/core";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthContext } from "../auth/auth";
import instance from "../axios/global";
import { Loader } from "@mantine/core";
import yngroup from "../assets/yuriandneilgroup.svg";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Ce champ est requis")
    .matches(
      "yuriandneil.com",
      "Vous devez utiliser votre adresse email yuriandneil.com"
    ),
  password: yup
    .string()
    .min(3, "Mot de passe incorrect ou manquant")
    .required("Ce champ est requis"),
});

const Login = () => {
  const { setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await instance.post("/user/login", data);
      localStorage.setItem("accessToken", res.data.token);
      localStorage.setItem("user", JSON.stringify(res.data.user));
      setLoading(false);
      setAuthState({
        id: res.data.user.id,
        name: res.data.user.firstName,
        email: res.data.user.email,
        role: res.data.user.role,
        status: true,
      });
      // Navigue vers la page précédente ou "/employes" par défaut
      navigate(location.state?.from?.pathname || "/employes");
      setError(null);
    } catch (error) {
      // ... gestion des erreurs
    }
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      navigate("/employes");
    }
  }, [navigate]);

  return (
    <div className="container-login">
      <div className="left-container">
        <div className="titles">
          <h1>Cockpit Manager</h1>
          <h2>Bienvenue sur votre outil de gestion</h2>
        </div>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Email"
            placeholder="hello@yuriandneil.com"
            size="md"
            {...register("email")}
            type="email"
            name="email"
          />
          <span className="warning-msg">
            {errors.email && errors.email.message}
          </span>
          <PasswordInput
            label="Mot de passe"
            placeholder="********"
            mt="md"
            size="md"
            name="password"
            {...register("password")}
          />
          <span className="warning-msg">
            {errors.password && errors.password.message}
          </span>
          <Checkbox label="Se souvenir de moi" mt="md" size="md" />
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <Loader color="blue" size="lg" />
            </div>
          ) : (
            <Button fullWidth type="submit" mt="md" size="md">
              Connexion
            </Button>
          )}
        </form>
      </div>
      <div className="right-container">
        <div className="yn-group-div">
          <img src={yngroup} alt="yuriandneil-group" />
        </div>
      </div>
    </div>
  );
};

export default Login;
