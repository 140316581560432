import { createContext, useState, useEffect } from "react";
import { differenceInCalendarMonths } from "date-fns";

export const BdcContext = createContext(null);

const BdcContextProvider = ({ children }) => {
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [bdcNumber, setBdcNumber] = useState("");
  const [accountingCode, setAccountingCode] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [bdcAmount, setBdcAmount] = useState("");
  const [estimatedMargin, setEstimatedMargin] = useState("");
  const [attributionSchema, setAttributionSchema] = useState([]);
  const [consultantList, setConsultantList] = useState([]);
  const [purchaseToAssign, setPurchaseToAssign] = useState(0);
  const [totalAttribution, setTotalAttribution] = useState();
  const [bdcName, setBdcName] = useState("");
  const [comment, setComment] = useState("");
  const [initialAmount, setInitialAmount] = useState();
  const [finalAmount, setFinalAmount] = useState(initialAmount);
  const [numMonths, setNumMonths] = useState(0);
  const [bdcType, setBdcType] = useState("");

  return (
    <BdcContext.Provider
      value={{
        bdcType,
        setBdcType,
        clientId,
        finalAmount,
        initialAmount,
        setFinalAmount,
        setInitialAmount,
        setClientId,
        clientName,
        setClientName,
        bdcNumber,
        setBdcNumber,
        accountingCode,
        setAccountingCode,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        bdcAmount,
        setBdcAmount,
        estimatedMargin,
        setEstimatedMargin,
        attributionSchema,
        setAttributionSchema,
        consultantList,
        setConsultantList,
        purchaseToAssign,
        setPurchaseToAssign,
        bdcName,
        setBdcName,
        totalAttribution,
        setTotalAttribution,
        numMonths,
        comment,
        setComment,
      }}
    >
      {children}
    </BdcContext.Provider>
  );
};

export default BdcContextProvider;
