import React, { useContext, useState } from "react";
import { parse } from "papaparse";
import { ScrollArea } from "@mantine/core";
import drop from "../assets/drop.svg";
import instance from "../axios/global";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/auth";
import exemple2 from "../assets/cockpit-manager-attribution-fichier-exemple.csv";
import important from "../assets/warning-filled.svg";
import ErrorMessage from "../components/ErrorMessage";
import ImportantError from "../components/ImportantErrorMessage";
import LoaderComp from "../components/LoaderComp";

const ImportAttribution = () => {
  const [parsedCsvData, setParsedCsvData] = useState([]);
  const { authState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingApiResponse, setLoadingApiResponse] = useState(false);
  const [errorChild, setErrorChild] = useState();
  const [file, setFile] = useState(null);
  const [importantError, setImportantError] = useState(null);
  const navigate = useNavigate();

  const handleSubmitBdcData = async () => {
    setLoadingApiResponse(true);
    if (parsedCsvData.length !== 0) {
      const formDataWithCSV = new FormData();
      const csvFile = file;
      formDataWithCSV.append("file", csvFile);
      formDataWithCSV.append("user", JSON.stringify(authState.id));
      const resWithCSV = await instance.post(
        "/bdc/file/withcsv",
        formDataWithCSV
      );
      if (resWithCSV.data.message === "") {
        console.log("resWithCSV :>> ", resWithCSV);
        throw new Error(
          "Erreur lors de la première requête avec le fichier CSV"
        );
      }

      const parsedCsvfile = parsedCsvData?.filter((el) => {
        return el["Nom Affaire"] && el["N° Affaire"];
      });

      const parsedCsvForApi = parsedCsvfile.map((client, index) => {
        console.log("client :>> ", client);
        const DatedeDebut = moment(client["Date Debut"], "DD/MM/YYYY");
        const DatedeFin = moment(client["Date Fin"], "DD/MM/YYYY");

        // Vérifier si les dates sont valides
        if (
          !DatedeDebut.isValid() ||
          !DatedeFin.isValid() ||
          !DatedeDebut.isSameOrBefore(DatedeFin)
        ) {
          console.log(
            "Invalid or inconsistent dates found. Skipping this entry."
          );
          toast.error(
            `Date invalide pour le bdcs ${client["N° Affaire"]} il sera ignoré`,
            {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            }
          );
          return null; // Ignore this entry
        }

        const formattedDateDebut = DatedeDebut.format("YYYY-MM");
        const formattedDateFin = DatedeFin.format("YYYY-MM");
        const myString = client["Total devis Client TTC"];
        const myNumber = myString.replace(/\s/g, "").replace("€", "");
        const myInteger = Math.floor(myNumber);

        const myString2 = client["Marge Estime"];
        const myNumber2 = myString2
          .replace(/\s/g, "")
          .replace(",", ".")
          .replace("€", "");
        const myInteger2 = Math.floor(Number(myNumber2));

        return {
          client_name: client.Client,
          name: client["Nom Affaire"],
          number: client["N° Affaire"],
          accounting_code: client.CodeComptable,
          start_date: formattedDateDebut,
          end_date: formattedDateFin,
          estimated_margin: myInteger2,
          amount_ttc: myInteger,
          attribution: client.Attribution,
          numOfMonth: client.Mois === "0" ? "1" : client.Mois,
          consultants: client.consultants,
          userId: authState.id,
        };
      });

      // Filtrer les entrées null (dates invalides) de parsedCsvForApi
      const validEntries = parsedCsvForApi.filter((entry) => entry !== null);

      try {
        const res = await instance.post("/bdc/attribution/bulk", {
          data: validEntries,
        });
        setLoadingApiResponse(false);
        toast.success("Bons de commandes envoyés", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });

        navigate("/bdc");
      } catch (error) {
        toast.error("Error !", {
          position: toast.POSITION.TOP_LEFT,
        });

        console.log("error :>> ", error);
      }
    } else {
      toast.error("No bdc to send !", {
        position: toast.POSITION.TOP_LEFT,
      });

      throw new Error("pas de bdc pas d'envoi");
    }
  };

  const removeSpace = () => {
    parsedCsvData.forEach(function (e, i) {
      // Iterate over the keys of object
      Object.keys(e).forEach(function (key) {
        // Copy the value
        var val = e[key],
          newKey = key.replace(/\s+/g, "");

        // Remove key-value from object
        delete parsedCsvData[i][key];

        // Add value with new key
        parsedCsvData[i][newKey] = val;
      });
    });
  };
  const disabledButtonClass = "disabled-button";
  const filteredCsvData = parsedCsvData.filter(
    (item) => item["N° Affaire"] !== ""
  );

  const parsedCsvDataWithValidity = filteredCsvData.map((item) => {
    // Validation de base
    const basicValidation =
      item["N° Affaire"] &&
      item["Date Debut"] &&
      item["Date Fin"] &&
      item["N° Affaire"] &&
      item["Nom Affaire"] &&
      item.Client &&
      item["Total devis Client TTC"] &&
      item["Marge Estime"] &&
      item["Attribution"];

    // Validation des valeurs des consultants
    const attributionValidation =
      item["Attribution"] === "renew" || item["Attribution"] === "newbiz";

    // Vérification de la date de début et de la date de fin
    const DatedeDebut = moment(item["Date Debut"], "DD/MM/YYYY");
    const DatedeFin = moment(item["Date Fin"], "DD/MM/YYYY");
    const isValidDate = DatedeDebut.isValid() && DatedeFin.isValid();
    const dateValidation = isValidDate && DatedeDebut.isSameOrBefore(DatedeFin);

    // Si la validation de base échoue ou la validation de la date échoue ou la validation d'attribution échoue, l'élément est invalide
    if (!basicValidation || !dateValidation || !attributionValidation) {
      return {
        ...item,
        isValid: false,
      };
    }

    // Validation des valeurs des consultants
    const consultantValidation = item.consultants.every((consultant) => {
      const value = consultant.value.trim(); // Supprimer les espaces autour de la valeur
      const isValidPercentage = /^\d+%$/.test(value); // Vérifie si la valeur est un pourcentage valide (nombre suivi de %)
      if (!isValidPercentage) {
        return false;
      }
      const numericValue = parseInt(value); // Convertir en nombre entier
      if (isNaN(numericValue) || numericValue < 0 || numericValue > 100) {
        return false;
      }
      return true;
    });

    // Si la validation des consultants échoue, l'élément est invalide
    if (!consultantValidation) {
      return {
        ...item,
        isValid: false,
      };
    }

    // Calcul du total des valeurs des consultants
    const totalConsultantValue = item.consultants.reduce(
      (total, consultant) => {
        const value = parseInt(consultant.value); // Convertir la valeur en nombre entier
        return total + value;
      },
      0
    );

    // Si le total dépasse 100%, l'élément est invalide
    if (totalConsultantValue > 100) {
      return {
        ...item,
        isValid: false,
      };
    }

    // Si toutes les validations passent, l'élément est valide
    return {
      ...item,
      isValid: true,
    };
  });

  const invalidItems = parsedCsvDataWithValidity.filter(
    (item) => !item.isValid
  );
  const getErrorType = (item) => {
    if (!item.isValid) {
      const errors = [];
      if (!item["N° Affaire"]) errors.push("Numero d'affaire manquante");
      if (!item["Attribution"])
        errors.push("Type d'attribution non renseigné (renew ou newbiz)");
      else if (
        item["Attribution"] !== "renew" &&
        item["Attribution"] !== "newbiz"
      )
        errors.push("Type d'attribution non valide (renew ou newbiz)");
      if (!item["Date Debut"]) errors.push("Date de début manquante");
      if (!item["Date Fin"]) errors.push("Date de fin manquante");
      if (!item["Nom Affaire"]) errors.push("Nom du BDC manquant");
      if (!item["Client"]) errors.push("Client manquant");
      if (!item["Total devis Client TTC"]) errors.push("Valeur TTC manquante");
      if (!item["Marge Estime"]) errors.push("Marge estimée manquante");
      if (item["Date Debut"] && item["Date Fin"]) {
        const DatedeDebut = moment(item["Date Debut"], "DD/MM/YYYY");
        const DatedeFin = moment(item["Date Fin"], "DD/MM/YYYY");
        if (DatedeDebut.isAfter(DatedeFin)) {
          errors.push("La date de fin est antérieure à la date de début");
        }
      }
      if (item.consultants) {
        let totalConsultantPercentage = 0;
        item.consultants.forEach((consultant) => {
          const consultantValue = consultant.value;
          const valueWithoutPercentage = consultantValue.slice(0, -1);
          totalConsultantPercentage += parseInt(valueWithoutPercentage);
          if (!/^\d+%$/.test(consultantValue)) {
            errors.push(
              /*  `La valeur "${consultantValue}" pour le consultant "${consultant.name}" n'est pas un pourcentage valide.` */
              "Une attribution n'est pas un entier avec un % à la fin"
            );
          } else {
            const valueWithoutPercentage = consultantValue.slice(0, -1);
            if (
              valueWithoutPercentage.includes(".") ||
              valueWithoutPercentage.includes(",")
            ) {
              errors.push(
                /* `La valeur "${consultantValue}" pour le consultant "${consultant.name}" ne doit pas contenir de virgule.` */
                "Une attribution n'est pas un entier avec un % à la fin"
              );
            } else if (isNaN(parseInt(valueWithoutPercentage))) {
              errors.push(
                /* `La valeur "${consultantValue}" pour le consultant "${consultant.name}" ne doit contenir que des chiffres.` */
                "Une attribution n'est pas un entier avec un % à la fin"
              );
            } else {
              totalConsultantPercentage += parseInt(valueWithoutPercentage);
            }
          }
        });
        if (totalConsultantPercentage > 100) {
          errors.push(
            `La somme des pourcentages des consultants est supérieure à 100%.`
          );
        }
      }
      if (errors.length > 0) {
        return errors.join(" | ");
      }
    }
    return "Aucune erreur";
  };

  return (
    <>
      <section className="importBDC-page">
        <h1>Import attributions</h1>

        {loadingApiResponse ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "8rem",
            }}
          >
            <LoaderComp size="lg" />
          </div>
        ) : (
          <div
            className="ondrop-div"
            onDragOver={(event) => {
              event.preventDefault();
            }}
            onDrop={(event) => {
              event.preventDefault();
              setLoading(true);
              setImportantError(null);
              setParsedCsvData([]);

              Array.from(event.dataTransfer.files).map(async (file) => {
                setFile(file);
                let text = await file.text();
                let resultIndex = parse(text);
                let result = parse(text, { header: true });
                const rowElements = resultIndex.data.slice(1);
                // Supprimer les clés indésirables
                const keysToRemove = [
                  "Q1 2019",
                  "Q1 2020",
                  "Q1 2021",
                  "Q1 2022",
                  "Q1 2023",
                  "Q1 2024",
                  "Q2 2019",
                  "Q2 2020",
                  "Q2 2021",
                  "Q2 2022",
                  "Q2 2023",
                  "Q2 2024",
                  "Q3 2019",
                  "Q3 2020",
                  "Q3 2021",
                  "Q3 2022",
                  "Q3 2023",
                  "Q3 2024",
                  "Q4 2019",
                  "Q4 2020",
                  "Q4 2021",
                  "Q4 2022",
                  "Q4 2023",
                  "PartYuriAndNeil",
                  "Effortcommercial",
                  "MargeEstiméeYN",
                  "MB",
                  "Part Yuri And Neil",
                  "Marge Estimée YN",
                  "Effort commercial",
                ];
                const expectedValues = [
                  { index: 0, value: "N° Affaire" },
                  { index: 1, value: "Nom Affaire" },
                  { index: 2, value: "Client" },
                  { index: 3, value: "Date Debut" },
                  { index: 4, value: "Date Fin" },
                  { index: 5, value: "Marge Estime" },
                  { index: 6, value: "Attribution" },
                  { index: 7, value: "Total devis Client TTC" },
                  { index: 8, value: "other@other.com" },
                ];

                const errors = [];
                expectedValues.forEach(({ index, value }) => {
                  if (resultIndex.data[0][index] !== value) {
                    errors.push(
                      `Colonne ${
                        index + 1
                      } : Le nom de l'en-tête doit être "${value}"`
                    );
                  }
                });
                for (let i = 9; i < resultIndex.data[0].length; i++) {
                  const email = resultIndex.data[0][i];
                  if (!email.endsWith("@yuriandneil.com")) {
                    errors.push(
                      `Colonne ${
                        i + 1
                      } : l'email doit se terminer par "@yuriandneil.com"`
                    );
                  }
                }

                // for (let i = 0; i < rowElements.length; i++) {
                //   for (let j = 8; j < rowElements[i].length; j++) {
                //     const value = rowElements[i][j];
                //     if (typeof value !== "string" || value.trim() === "") {
                //       errors.push(
                //         `La ligne ${i + 1} de la colonne ${j + 1} est vide.`
                //       );
                //     } else if (!value.endsWith("%")) {
                //       errors.push(
                //         `La valeur à la ligne ${i + 1} colonne ${
                //           j + 1
                //         } ne se termine pas par "%".`
                //       );
                //     } else {
                //       const cleanedValue = value.replace("%", "");
                //       if (!/^\d+$/.test(cleanedValue)) {
                //         // Si la valeur n'est pas composée uniquement de chiffres, ajouter une erreur
                //         errors.push(
                //           `La valeur à la ligne ${i + 1} colonne ${
                //             j + 1
                //           } n'est pas un entier.`
                //         );
                //       }
                //     }
                //   }
                // }

                if (errors.length > 0) {
                  // Afficher une erreur ou prendre une action appropriée
                  // Par exemple :
                  // alert("Des erreurs ont été trouvées dans les valeurs des index.");
                  // return; // Arrêter le traitement ou revenir en arrière si nécessaire
                  // alert(
                  //   "Erreurs trouvées dans les valeurs des index :\n" +
                  //     errors.join("\n")
                  // );
                  setImportantError(errors);
                  setLoading(false);
                  return; // Arrêter le traitement si des erreurs sont trouvées
                } else {
                  // Remplir le state avec outputArray si aucune erreur n'est trouvée
                  // Suite du traitement si aucune erreur n'est trouvée
                  // ...
                  const filteredData = result?.data?.map((item) => {
                    keysToRemove.forEach((key) => delete item[key]);
                    for (let key in item) {
                      if (
                        key !== "N° Affaire" &&
                        key !== "Nom Affaire" &&
                        key !== "Client" &&
                        key !== "Date Debut" &&
                        key !== "Date Fin" &&
                        key !== "Mois" &&
                        key !== "Total devis Client TTC" &&
                        key !== "Marge Estime" &&
                        key !== "TOTAL" &&
                        key !== "Attribution" &&
                        (item[key] === "0%" ||
                          item[key] === "- €" ||
                          item[key] === "" ||
                          item[key] === "Mano" ||
                          item[key] === "Multi" ||
                          item[key] === "mano")
                      ) {
                        delete item[key];
                      }
                    }
                    return item;
                  });

                  const dataFiltered = filteredData?.filter(
                    (item) => item["N° Affaire"] !== ""
                  );
                  const outputArray = dataFiltered.map((obj) => {
                    const consultants = [];
                    const keys = Object.keys(obj);

                    keys.forEach((key) => {
                      if (
                        key !== "N° Affaire" &&
                        key !== "Nom Affaire" &&
                        key !== "Client" &&
                        key !== "Date Debut" &&
                        key !== "Date Fin" &&
                        key !== "Mois" &&
                        key !== "Total devis Client TTC" &&
                        key !== "Marge Estime" &&
                        key !== "TOTAL" &&
                        key !== "Attribution"
                      ) {
                        consultants.push({
                          name: key,
                          value: obj[key],
                        });
                        delete obj[key];
                      }
                    });

                    obj.consultants = consultants;
                    return obj;
                  });
                  setParsedCsvData(outputArray);
                  console.log("🚀 ~ Array.from ~ outputArray:", outputArray);
                }

                setLoading(false);
              });
            }}
          >
            {loading ? (
              <div className="loader">Chargement...</div>
            ) : (
              <>
                <img src={drop} alt="drop" />
                <p>Drag and drop votre fichier csv </p>
              </>
            )}
          </div>
        )}
        <div>
          <div className="exemple-link">
            <h2>
              Utilisez ce fichier pour attribuer automatiquement vos BDC :
              <a href={exemple2} download>
                Exemple de fichier à envoyer
              </a>
            </h2>

            {importantError ? (
              <ImportantError message={importantError} />
            ) : (
              <div className="container-warning-msg">
                <div className="logo-and-title">
                  <img src={important} alt="important" /> <h3>Important</h3>
                </div>
                <p>- Merci de conserver le nom des en-têtes</p>{" "}
                <p>- Ne pas ajouter ou supprimer des colonnes</p>{" "}
                <p>
                  - La colonne I "other@other.com", ne doit pas être supprimée
                  et correspond à un consultant fictif, si le bon de commande
                  n'est pas encore attribué.
                </p>
                <p>
                  - Dès la colonne L, inscrivez l'email des consultants que vous
                  souhaitez attribuer. Assurez-vous que l'adresse email soit
                  correcte, uniquement des @yuriandneil.com
                </p>
                <p>
                  - Toutes les cases d'attribution doivent être complétées avec
                  un % de 0% à 100%
                </p>
                <p>
                  - Copiez-Collez la mise en forme du fichier exemple sur votre
                  fichier
                </p>
              </div>
            )}
          </div>
        </div>
        <ScrollArea>
          {parsedCsvData.length > 0 && (
            <div>
              {parsedCsvDataWithValidity.every((item) => item.isValid) ? (
                <div className="succes-import-element">
                  <p className="valid-message">
                    Nous n'avons détecté aucune erreur. Vous pouvez envoyer les
                    BDCs.
                  </p>
                  <div className="div-button-import">
                    <button
                      className={
                        parsedCsvDataWithValidity.length === 0 ||
                        parsedCsvDataWithValidity.some((item) => !item.isValid)
                          ? disabledButtonClass
                          : ""
                      }
                      disabled={
                        parsedCsvDataWithValidity.length === 0 ||
                        parsedCsvDataWithValidity.some((item) => !item.isValid)
                      }
                      type="submit"
                      onClick={handleSubmitBdcData}
                    >
                      Envoyer les BDCS
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <h5
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    ❌ Nous ne pouvons pas importer vos BDCs, merci de corriger
                    ces erreurs :
                  </h5>
                  <table className="table">
                    <thead>
                      <tr className="table-tr">
                        <th>Numero Affaire</th>
                        {/* <th>Nom Affaire</th> */}
                        <th>Erreurs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {parsedCsvDataWithValidity
                        .filter((item) => !item.isValid)
                        .map((item, index) => (
                          <tr key={index}>
                            <td>{item["N° Affaire"]}</td>
                            {/*  <td>{item["Nom Affaire"]}</td> */}
                            <td>{getErrorType(item)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </ScrollArea>
      </section>
    </>
  );
};

export default ImportAttribution;
