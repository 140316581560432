import React, { useEffect, useState } from "react";
import instance from "../axios/global";
import { Table, Modal, useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import edit from "../assets/Edit.svg";
import trash from "../assets/Trash-2.svg";
import moment from "moment";
import { toast } from "react-toastify";
import NavBdc from "../components/NavBdc";

const ListBdcToCheck = () => {
  const [loading, setLoading] = useState(false);
  const [bdcToEdit, setBdcToEdit] = useState("");
  const [idBdc, setIdBdc] = useState("");
  const [opened, setOpened] = useState(false);

  const navigate = useNavigate();

  const theme = useMantineTheme();

  useEffect(() => {
    const fetchBdcToEdit = async () => {
      setLoading(true);
      try {
        const res = await instance.get("/bdc/allBdcUpdate");
        setBdcToEdit(res?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetchBdcToEdit();
  }, []);

  const handleDeleteBdc = async (id) => {
    try {
      const res = await instance.delete(`/bdc/delete/${id}`);
      setOpened(false);
      toast.success("Bon de commande supprimé", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    } catch (error) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_LEFT,
      });
      console.log("error :>> ", error);
    }
  };

  const rows =
    bdcToEdit &&
    bdcToEdit.map((element) => {
      const id = element.id;

      const startDate = moment(element.start_date, "YYYY-MM").format("MM-YYYY");
      const endDate = moment(element.end_date).format("MM-YYYY");

      console.log("element :>> ", element);
      return (
        <tr
          //   className={`tr-bdc-list ${
          //     element.status === "NA"
          //       ? "na"
          //       : element.status === "TA"
          //       ? "ta"
          //       : element.status === "PA"
          //       ? "pa"
          //       : ""
          //   }`}
          key={element.id}
        >
          <td>{element?.number ?? 0}</td>
          <td>{element?.client_name}</td>
          <td>{element?.name ? element.name : "no name"}</td>
          <td>{element?.amount_ttc ?? 0}</td>
          <td>{startDate}</td>
          <td>{endDate}</td>
          <td>{element.estimated_amount}</td>
          <td>{element.totalAttribution ?? 0 + "%"}</td>

          <td>
            <img
              onClick={() => {
                navigate(`/bdc/bdc-a-modifier/${element.bdcDoublonId}`);
              }}
              className="icon-action"
              src={edit}
              alt="edit"
            />

            <img
              className="icon-action"
              src={trash}
              alt="delete"
              onClick={() => {
                setOpened(true);
                setIdBdc(id);
              }}
            />
          </td>
        </tr>
      );
    });

  console.log("bdcToEdit :>> ", bdcToEdit);
  return (
    <div className="bdctocheck-page">
      <h1>Bon de commande à modifier</h1>

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        overlayOpacity={0.55}
        overlayBlur={3}
        transition="fade"
        transitionDuration={600}
        transitionTimingFunction="ease"
        size={650}
        padding={50}
      >
        <div className="container-confirm-delete">
          <div className="confirm-msg-delete">
            <p> Etes-vous sûr de vouloir supprimer cette affaire ?</p>
            <span>
              Vous ne pourrez plus revenir en arrière. Le bon de commande sera
              définitivement supprimer ainsi que toutes ses attributions.
            </span>
          </div>
          <div className="div-button-choice">
            <button className="btn-no" onClick={() => setOpened(false)}>
              Annuler
            </button>
            <button
              className="btn-yes"
              onClick={() => handleDeleteBdc(idBdc, console.log(idBdc))}
            >
              Supprimer
            </button>
          </div>
        </div>
      </Modal>
      <NavBdc />
      <div className="search-create">
        <div>
          <input
            className="search-bar"
            type="text"
            placeholder="Recheche bon de commande"
            // value={searchQuery}
            // onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div>
          <button
            style={{ cursor: "pointer", marginRight: "1rem" }}
            onClick={() => {
              navigate(`/creerBDC`);
            }}
          >
            Ajouter un BDC
          </button>

          <button
            className="btn-import-bdc"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/bdc/importBDC`);
            }}
          >
            Importer BDC
          </button>
        </div>
      </div>
      {bdcToEdit.length !== 0 ? (
        <table className="table">
          <thead className="thead-bdcs sticky-table-head">
            <tr>
              <th>Numéro d'affaire</th>
              <th>Nom Client</th>
              <th>Nom Bdc</th>
              <th>Montant TTC</th>
              <th>Date de début</th>
              <th>Date de fin</th>
              <th>Marge estimée</th>
              <th>Total attribution</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      ) : (
        ""
      )}
    </div>
  );
};

export default ListBdcToCheck;
