import { createContext, useState } from "react";

export const createBdcContext = createContext(null);

const CreateBdcContextProvider = ({ children }) => {
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [bdcNumber, setBdcNumber] = useState("");
  const [accountingCode, setAccountingCode] = useState("");
  const [startDateCreate, setStartDateCreate] = useState(new Date());
  const [endDateCreate, setEndDateCreate] = useState(new Date());
  const [bdcAmount, setBdcAmount] = useState("");
  const [estimatedMargin, setEstimatedMargin] = useState("");
  const [attributionSchema, setAttributionSchema] = useState([]);
  const [consultantList, setConsultantList] = useState([]);
  const [purchaseToAssign, setPurchaseToAssign] = useState(0);
  const [splitByEmployee, setSplitByEmployee] = useState();
  const [totalAttribution, setTotalAttribution] = useState();
  const [bdcName, setBdcName] = useState();
  const [initialAmount, setInitialAmount] = useState();
  const [percentages, setPercentages] = useState([]);
  const [finalAmount, setFinalAmount] = useState(initialAmount);
  const [numberOfMonth, setNumberOfMonth] = useState(0);
  const [consultant, setConsultant] = useState([
    {
      id: "",
      expertise: "",
      percentage: 0,
      attribution: "",
    },
  ]);

  return (
    <createBdcContext.Provider
      value={{
        initialAmount,
        consultant,
        setConsultant,
        setInitialAmount,
        percentages,
        setPercentages,
        finalAmount,
        setFinalAmount,
        clientId,
        setClientId,
        clientName,
        setClientName,
        bdcNumber,
        setBdcNumber,
        accountingCode,
        setAccountingCode,
        startDateCreate,
        setStartDateCreate,
        endDateCreate,
        setEndDateCreate,
        bdcAmount,
        setBdcAmount,
        estimatedMargin,
        setEstimatedMargin,
        attributionSchema,
        setAttributionSchema,
        consultantList,
        setConsultantList,
        purchaseToAssign,
        setPurchaseToAssign,
        bdcName,
        setBdcName,
        totalAttribution,
        setTotalAttribution,
        numberOfMonth,
        setNumberOfMonth,
      }}
    >
      {children}
    </createBdcContext.Provider>
  );
};

export default CreateBdcContextProvider;
