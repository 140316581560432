import React, { useState, useEffect, useContext } from "react";
import DisplayAllTotal from "../components/DisplayAllTotal";
import instance from "../axios/global";
import { AuthContext } from "../auth/auth";
import NavClient from "../components/NavClient";
import LoaderComp from "../components/LoaderComp";
import euro from "../assets/euro.svg";

const GlobalClients = () => {
  const [loading, setLoading] = useState(false);
  const { authState } = useContext(AuthContext);
  const [dataAll, setDataAll] = useState([]);
  const [thAll, setThAll] = useState([]);
  const [thList, setThList] = useState([]);
  const [clients, setClients] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [allTotalValue, setAllTotalValue] = useState(0);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await instance.get("/client/all/combine");
        setClients(
          res?.data.reduce((acc, curr) => {
            const { id, name, row } = curr;
            const cell = row?.map((el) => {
              return el?.cell;
            });
            const flat = cell?.flat();
            const result = Object.values(
              flat.reduce((acc, obj) => {
                const { date, value } = obj;
                if (!acc[date]) {
                  acc[date] = { date, value };
                } else {
                  acc[date].value += value;
                }
                return acc;
              }, {})
            );
            result.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });
            const totalValue = result.reduce(
              (acc, curr) => acc + parseFloat(curr.value),
              0.0
            );
            acc = acc.concat({
              id,
              name,
              result,
              total_value: totalValue,
            });
            return acc;
          }, [])
        );
        const formatData = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }

          const result = data.flatMap((curr) => {
            const { id, firstName, lastName, total_bdc, roles, row, image } =
              curr;

            const flat = row?.flatMap((el) => el?.cell) || [];
            return flat.map(({ date, value }) => ({ date, value }));
          });
          const totalValue = result.reduce((acc, curr) => acc + curr.value, 0);
          result.sort((a, b) => a.date.localeCompare(b.date));

          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});

          return [
            {
              Nom: data[0].name,
              "Total BDC": data[0].total_bdc,
              ...formattedData,
            },
          ];
        };
        const formatData2 = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }

          const result = data.flatMap((curr) => {
            const { id, firstName, lastName, total_bdc, roles, row, image } =
              curr;

            const flat = row?.flatMap((el) => el?.cell) || [];
            return flat.map(({ date, value }) => ({ date, value }));
          });
          const totalValue = result.reduce((acc, curr) => acc + curr.value, 0);
          result.sort((a, b) => a.date.localeCompare(b.date));

          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});

          return [
            {
              ...formattedData,
            },
          ];
        };
        setThList(formatData(res?.data));
        setThAll(formatData2(res?.data));

        setDataAll(
          res?.data?.map((curr) => {
            const { id, name, row } = curr;
            const cell = row?.flat().map((el) => {
              return el?.cell;
            });
            const flat = cell?.flat();
            const aggregatedResult = flat.reduce((acc, obj) => {
              let { date, value } = obj;
              if (!acc[date]) {
                acc[date] = 0.0;
              }
              acc[date] += parseFloat(value);
              return acc;
            }, {});
            const result = Object.entries(aggregatedResult).map(
              ([date, value]) => ({
                date,
                value,
              })
            );
            result.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });
            return {
              ...Object.fromEntries(
                result.map(({ date, value }) => [date, value])
              ),
            };
          })
        );
        setDataToDisplay(
          res?.data?.map((curr) => {
            const { id, name, row } = curr;
            const cell = row?.flat().map((el) => {
              return el?.cell;
            });
            const flat = cell?.flat();
            const aggregatedResult = flat.reduce((acc, obj) => {
              let { date, value } = obj;
              if (!acc[date]) {
                acc[date] = 0.0;
              }
              acc[date] += parseFloat(value);
              return acc;
            }, {});
            const result = Object.entries(aggregatedResult).map(
              ([date, value]) => ({
                date,
                value,
              })
            );
            const totalValue = result.reduce(
              (acc, curr) => acc + parseFloat(curr.value),
              0.0
            );
            result.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });
            return {
              id,
              Nom: name,
              "Total BDC": `${totalValue.toLocaleString()}€`,
              ...Object.fromEntries(
                result.map(({ date, value }) => [
                  date,
                  `${value.toLocaleString()}€`,
                ])
              ),
            };
          })
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("🚀 ~ file: Clients.js:20 ~ fetchClients ~ error", error);
      }
    };
    fetchClients();
  }, []);

  useEffect(() => {
    // Calculer le total de toutes les valeurs de total_value
    const total = clients.reduce((acc, curr) => {
      return acc + parseInt(curr?.total_value.toFixed(2));
    }, 0.0);
    setAllTotalValue(total);
  }, [clients]);
  return (
    <div className="container-all-clients">
      <h1>Clients</h1>
      <div className="div-total-create-client">
        <NavClient />

        <div className="descrip-totals" style={{ flexDirection: "column" }}>
          <p>Total bon de commandes attribué</p>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <span>
              {allTotalValue ? `${allTotalValue.toLocaleString()}` : `${0.0}€`}{" "}
            </span>
            <img src={euro} alt="euro" />
          </div>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <LoaderComp />
        </div>
      ) : (
        <div>
          {loading ? null : (
            <div>
              <span className="total-mois">Total par mois</span>
              <DisplayAllTotal dataAll={dataAll} thAll={thAll} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GlobalClients;
