import React, { useState, useEffect } from "react";
import "dayjs/locale/fr";
import { useNavigate } from "react-router-dom";
// import Step1 from "../containers/form BDC/Step1";
// import Step2 from "../containers/form BDC/Step2";
// import Step3 from "../containers/form BDC/Step3";
import * as moment from "moment";
import instance from "../axios/global";
import { toast } from "react-toastify";
import useCreateBdc from "../hooks/useCreateBdc";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import CreateClient from "../components/CreateClient";
import { DatePicker } from "@mantine/dates";
import { differenceInCalendarMonths } from "date-fns";
import { Tooltip } from "react-tooltip";
import question from "../assets/Question.svg";
import euro from "../assets/euro.svg";
import close from "../assets/delete.svg";

const CreationBDC = () => {
  const [accountingCode, setAccountingCode] = useState();
  const [refresh, setRefresh] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [consultantList, setConsultantList] = useState([]);

  const {
    clientId,
    setClientId,
    startDateCreate,
    endDateCreate,
    bdcNumber,
    clientName,
    setClientName,
    // accountingCode,
    bdcAmount,
    consultant,
    bdcName,
    estimatedMargin,
    finalAmount,
    numberOfMonth,
    setBdcNumber,
    setStartDateCreate,
    setEndDateCreate,
    setBdcAmount,
    setAttributionSchema,
    setEstimatedMargin,
    setBdcName,
    setInitialAmount,
    initialAmount,
    setConsultant,
    setFinalAmount,
    percentages,
    setPercentages,
    setNumberOfMonth,
  } = useCreateBdc();
  console.log(
    "🚀 ~ file: CreationBDC.js:58 ~ CreationBDC ~ clientId:",
    clientId
  );

  const navigate = useNavigate();
  const [displayAmount, setDisplayAmount] = useState(initialAmount);

  useEffect(() => {
    instance
      .get(`/client/all`)
      .then((res) => {
        // console.log("res.data :>> ", res.data);
        setAllClients(res.data);
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  }, [refresh]);

  const bdcSchema = yup.object().shape({
    //number("salut").required("yo").positive("ok").integer("int"),
    number: yup
      .number("Le numéro de bdc doit être un chiffre")
      .typeError("Veuillez renseigner le numéro du bdc")
      .required("Veuillez renseigner le numéro du bdc")
      .positive("Le nombre doit être positif"),
    name: yup.string().required("Veuillez renseigner le nom du bdc"),
    //clientName: yup.string().required("Veuillez choisir un client"),
    accounting_code: yup
      .string()
      .required("Veuillez renseigner le code comptable"),
    estimated_amount: yup
      .number()
      .typeError("Veuillez renseigner la valeur TTC du bdc")
      .required("Veuillez renseigner la valeur TTC du bdc")
      .positive("Le montant doit être positif"),
    amount_ttc: yup
      .number()
      .typeError("Veuillez renseigner la marge estimée du bdc")
      .required("Veuillez renseigner la marge estimée du bdc")
      .positive("Le montant doit être positif"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(bdcSchema),
  });

  const handleSubmitBdData = async (e) => {
    // e.preventDefault();
    const now = moment(startDateCreate).format("YYYY-MM");
    const now2 = moment(endDateCreate).format("YYYY-MM");
    console.log("numberOfMonth :>> ", numberOfMonth);
    console.log(
      "🚀 ~ file: CreationBDC.js:49 ~ handleSubmitBdData ~ consultant:",
      consultant
    );
    console.log("bdcName :>> ", bdcName);
    try {
      const res = await instance.post(`/bdc/create`, {
        number: bdcNumber,
        name: bdcName,
        clientName: clientName,
        accounting_code: accountingCode,
        start_date: now,
        end_date: now2,
        estimated_amount: bdcAmount,
        consultants: consultant,
        clientId: clientId,
        amount_ttc: estimatedMargin,
        totalAttribution: finalAmount,
        numberOfMonth: numberOfMonth,
        bdcName: bdcName,
      });
      console.log(
        "🚀 ~ file: CreationBDC.js:49 ~ handleSubmitBdData ~ consultant:",
        consultant
      );

      //navigate("/bdc");
      toast.success("Votre bon de commande a bien été crée.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
    } catch (error) {
      toast.error(error.response.data.error, {
        position: toast.POSITION.TOP_LEFT,
      });
      console.log(
        "🚀 ~ file: CreationBDC.js:24 ~ handleSubmitBdcData ~ error",
        error
      );
    }
  };

  const options =
    allClients &&
    allClients
      .map((el) => {
        return {
          value: el.id,
          label: el.name,
          name: "name",
          id: el.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,

      //borderBottom: "1px dotted #373a40",
      color: state.isSelected ? "#0A4BFF" : "black",
      padding: 20,

      background: "#ffffff",
      fontSize: "15px",
      "&:hover": {
        background: "#F0F3F8",
      },
    }),

    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),

    indicatorSeparator: () => ({
      background: "none",
    }),

    indicatorsContainer: () => ({
      // borderLeft: "1px solid #25262b",
      marginRight: "2px",
      cursor: "pointer",
    }),
    placeholder: (base) => ({
      ...base,
      height: "36px",
      color: "#6B6B6C",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      height: 36,
      width: 326,
      backgroundColor: "#FFFFFF",
      border: "1px solid #E3E3E3",
      borderRadius: "4px",
      fontSize: "14px",
      paddingLeft: 12,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      // background: "#25262b",
    }),
  };

  // STEP 3 functions
  useEffect(() => {
    const fetchConsultant = async () => {
      try {
        const res = await instance.get("/user/all");
        setConsultantList(res.data);
      } catch (error) {
        //  console.log("🚀 ~ file: Step3.js:13 ~ fetchConsultant ~ error", error);
      }
    };
    fetchConsultant();
  }, []);

  function updateAmounts(newPercentages) {
    setPercentages(newPercentages);
    const updatedAmount = newPercentages.reduce((total, percentage) => {
      return total - (percentage * initialAmount) / 100;
    }, initialAmount);
    setFinalAmount(updatedAmount);
    //console.log("updatedAmount :>> ", updatedAmount);
  }

  const handleAddMembers = () => {
    setConsultant([...consultant, { firstName: "", id: "" }]);
    console.log("setConsultant :>> ", setConsultant);
  };

  const handleRemoveConsultant = (index) => {
    const values = [...consultant];
    values.splice(index, 1);
    setConsultant(values);
  };

  const expertise = [
    { value: "All", label: "All", name: "expertise" },
    { value: "Content Factory", label: "Content Factory", name: "expertise" },
    {
      value: "Content marketing",
      label: "Content Marketing",
      name: "expertise",
    },
    { value: "Data", label: "Data", name: "expertise" },
    { value: "Design", label: "Design", name: "expertise" },
    { value: "Learning", label: "Learning", name: "expertise" },
    { value: "Media", label: "Media", name: "expertise" },
    { value: "R&D", label: "R&D", name: "expertise" },
    { value: "SEO", label: "SEO", name: "expertise" },

    { value: "Social media", label: "Social media", name: "expertise" },
  ];

  const handleExpertise = (obj, index) => {
    const { value, name } = obj;
    console.log("obj :>> ", obj);
    const list = [...consultant];
    list[index][name] = value;
    // console.log(
    //   'list[index]["id"] = id; :>> ',
    //   (list[index]["expertise"] = value)
    // );
    setConsultant(list);
  };
  const handleBdcValue = (e, index) => {
    const { value } = e.target;
    const list = [...consultant];
    list[index]["montant"] = value;
    setConsultant(list);
  };
  const handleAttribution = (e, index) => {
    const { value } = e.target;
    const list = [...consultant];
    list[index]["attribution"] = value;
    setConsultant(list);
  };
  const handleConsultant = (obj, index) => {
    const { value, name } = obj;
    console.log("value :>> ", value);
    console.log("obj :>> ", obj);
    const list = [...consultant];
    list[index]["id"] = value;
    //console.log('list[index]["id"] = id; :>> ', (list[index]["id"] = value));
    setConsultant(list);
    // console.log("member :>> ", member);
  };

  function handlePercentageChange(index, value) {
    const newPercentages = [...percentages];
    newPercentages[index] = value;
    updateAmounts(newPercentages);
  }

  const numMonths =
    differenceInCalendarMonths(
      new Date(endDateCreate),
      new Date(startDateCreate)
    ) + 1;

  useEffect(() => {
    setNumberOfMonth(numMonths);
  }, [numMonths]);

  useEffect(() => {
    if (finalAmount === 0) {
      setDisplayAmount(0);
    } else {
      setDisplayAmount(finalAmount || initialAmount);
    }
  }, [finalAmount, initialAmount]);
  return (
    <>
      <header className="header-create-BDC">
        <h1>Création bon de commande</h1>
      </header>
      <main className="container-create-BDC">
        <div>
          <div className="div-choose-client">
            <h2>Client</h2>
            <h3>Affecter un client a votre bon de commande.</h3>
            <CreateClient />
          </div>
        </div>
        <form onSubmit={handleSubmit(handleSubmitBdData)}>
          <section className="creationBDC-client">
            {/* <h2>1- Clients</h2> */}

            <Select
              styles={customStyles}
              style={{ marginTop: 20, color: "blue" }}
              label="Nos clients"
              placeholder="Choisissez votre client"
              searchable
              required
              nothingFound="No options"
              options={options}
              onChange={(obj) => {
                // setClientId
                console.log(obj, "obj");
                setClientId(obj.id);
                setClientName(obj.label);
              }}
            />
          </section>
          <section className="creationBDC-bdc">
            <h2>Informations du bon de commande</h2>
            <h3>
              Toutes les informations importantes concernant votre bon de
              commande.
            </h3>
            <div className="container-label-input">
              <div className="label-input">
                <label htmlFor="">
                  Numéro <span>*</span>
                </label>
                <input
                  id="number"
                  type="number"
                  name="number"
                  placeholder="Entrez le numéro de votre bon de commande"
                  {...register("number")}
                  onChange={(e) => {
                    setBdcNumber(e.target.value);
                  }}
                />
                <span className="warning-msg">
                  {errors.number && errors.number.message}
                </span>
              </div>
              <div className="label-input-2">
                <label htmlFor="">
                  Nom <span>*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Entrez le nom de votre bdc"
                  {...register("name", {
                    required: "Required",
                  })}
                  onChange={(e) => {
                    setBdcName(e.target.value);
                  }}
                />
                <span className="warning-msg">
                  {errors.name && errors.name.message}
                </span>
              </div>
            </div>
            <div className="container-date-picker">
              <DatePicker
                locale="fr"
                style={{ marginTop: 20 }}
                label="Date de début"
                placeholder=""
                value={new Date(startDateCreate)}
                //classNames={classes}
                // clearable={true}
                //value={startDate}
                onChange={setStartDateCreate}
                required
              />
              <DatePicker
                locale="fr"
                style={{ marginTop: 20, marginLeft: 80 }}
                value={new Date(endDateCreate)}
                label="Date de fin"
                placeholder=""
                //classNames={classes}
                // clearable={false}
                //value={endDate}
                onChange={setEndDateCreate}
                required
              />
            </div>
            <div className="container-label-input">
              <div className="label-input">
                <label>
                  Code comptable <span>*</span>
                </label>
                <input
                  {...register("accounting_code", {
                    required: "Required",
                  })}
                  onChange={(e) => {
                    setAccountingCode(e.target.value);
                  }}
                  type="text"
                  placeholder="Entrez votre code comptable"
                />
                <span className="warning-msg">
                  {errors.accounting_code && errors.accounting_code.message}
                </span>
              </div>
              <div className="label-input-2">
                <label htmlFor="">
                  Valeur TTC <span>*</span>
                </label>
                <input
                  id="valeurBDC"
                  {...register("estimated_amount")}
                  onChange={(e) => {
                    setEstimatedMargin(e.target.value);
                  }}
                  type="number"
                  placeholder="Entrez le montant de votre BDC"
                  min={0}
                />
                <span className="warning-msg">
                  {errors.estimated_amount && errors.estimated_amount.message}
                </span>
              </div>
            </div>
            <div className="container-label-input">
              <div className="label-input">
                <label htmlFor="">
                  Marge estimée <span>*</span>
                </label>
                <input
                  id="margeBDC"
                  type="number"
                  placeholder="Entrez le montant de votre BDC"
                  {...register("amount_ttc", {
                    required: "Required",
                  })}
                  onChange={(e) => {
                    setBdcAmount(e.target.value);
                    setInitialAmount(e.target.value);
                  }}
                  min={0}
                />
                <span className="warning-msg">
                  {errors.amount_ttc && errors.amount_ttc.message}
                </span>
              </div>
            </div>
          </section>
          <section className="creationBDC-attribution">
            <h2>Attribution du bon de commande</h2>
            <h3>
              Attribuez un ou plusieurs consultants et expertise a votre bon de
              commande.
            </h3>
            <div className="select-total">
              <div className="total-BDC">
                <div className="div-total-euro">
                  <p>Total du bon de commande à attribuer : </p>
                  <img src={euro} alt="€" />
                </div>

                <span>{displayAmount}€</span>
              </div>
              {consultant.map((el, index) => {
                const option =
                  consultantList &&
                  consultantList
                    .map((el) => {
                      return {
                        value: el.id,
                        label: el.lastName + " " + el.firstName,
                        name: "name",
                      };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));

                return (
                  <div key={index}>
                    {index === 0 ? (
                      ""
                    ) : (
                      <div className="div-img-delete">
                        <img
                          src={close}
                          alt="close"
                          className="img-delete"
                          onClick={handleRemoveConsultant}
                        />
                      </div>
                    )}
                    <div className="select-input">
                      <Select
                        styles={customStyles}
                        label="Consultants"
                        placeholder="Ajoutez un consultant"
                        searchable
                        nothingFound="No options"
                        options={option}
                        onChange={(obj) => handleConsultant(obj, index)}
                      />
                      <div className="label-input-2">
                        <label htmlFor="">Pourcentage</label>
                        <input
                          id="montantConsultant"
                          type="number"
                          min={0}
                          max={100}
                          placeholder="Entrez le montant du consultant"
                          onBlur={(event) => {
                            let value = event.target.value;
                            if (value > 100) {
                              value = 100;
                              event.target.value = value; // réinitialisation de la valeur de l'input
                            } else if (value < 0) {
                              value = 0;
                              event.target.value = value; // réinitialisation de la valeur de l'input
                            }
                          }}
                          onChange={(event) => {
                            // setPercentageByUser(event.target.value);
                            handleBdcValue(event, index);
                            handlePercentageChange(index, event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="select-input">
                      <Select
                        styles={customStyles}
                        style={{ marginTop: 20, color: "blue" }}
                        label="Expertise"
                        placeholder="Ajoutez l'expertise du consultant"
                        searchable
                        nothingFound="No options"
                        options={expertise}
                        onChange={(obj) => handleExpertise(obj, index)}
                      />
                      <div className="container-label-input">
                        <div className="label-input-2">
                          <label
                            htmlFor=""
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            Schema d'attribution - Nombre de mois : {numMonths}
                            <p
                              style={{ marginLeft: "20px", color: "white" }}
                              data-tooltip-id="my-tooltip-multiline"
                              data-tooltip-html="En mettant 'renew': La marge sera répartie de manière égale chaque mois. <br />
En mettant 'newbiz' : La marge sera de 20% le 1er mois puis répartie de manière égale chaque mois.<br />
Modèle personnalisé : Pour attribuer la marge de manière personnalisée,<br /> vous devez indiquer le pourcentage pour chaque mois, en les séparant par une virgule. <br />Si un mois n'a pas de marge, vous devez mettre 0. <br />Exemple pour 3 mois à 10%, 20% et 70% : 10%,20%,70%"
                            >
                              <img src={question} alt="tooltip" />
                            </p>
                            <Tooltip id="my-tooltip-multiline"></Tooltip>
                          </label>
                          <input
                            id="valeurBDC"
                            onChange={(e) => {
                              setAttributionSchema(e.target.value);
                              handleAttribution(e, index);
                            }}
                            type="text"
                            placeholder="Spliter le bdc ex 20%,10%,30%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="div-add-another">
              <span className="span-add-another" onClick={handleAddMembers}>
                + Ajouter un consultant
              </span>
            </div>
          </section>

          {/* <section className="creationBDC-autre">
            <h2>4- Autre</h2>
            <div className="label-input">
              <label htmlFor="">Dépenses supplémentaires ?</label>
              <input
                type="text"
                placeholder="Entrez les dépenses supplémentaires"
              />
            </div>
          </section> */}
          <button className="btn-create-bdc" type="submit">
            Créer un BDC
          </button>
        </form>
      </main>
    </>
  );
};

export default CreationBDC;
