import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import instance from "../axios/global";
import StickyTable from "react-sticky-table-thead";
import { getClass } from "../utils/utils";
import { useTable, useGlobalFilter } from "react-table";
import CreateClient from "../components/CreateClient";

import LoaderComp from "../components/LoaderComp";
import NavClient from "../components/NavClient";
import { GlobalFilter } from "../components/GlobalFilter";

const Clients = () => {
  // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [thList, setThList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [allTotalValue, setAllTotalValue] = useState(0.0);
  const [dataAll, setDataAll] = useState([]);
  const [thAll, setThAll] = useState([]);

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    // Fonction pour faire défiler jusqu'en haut de la page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Pour une animation de défilement en douceur
    });
  };
  window.addEventListener("scroll", handleScroll);
  const data = React.useMemo(() => dataToDisplay, [dataToDisplay]);
  const columns = React.useMemo(() => {
    if (!thList || thList.length === 0) {
      return []; // Retourner une liste de colonnes vide si thList est undefined ou vide
    }

    // Générer dynamiquement les colonnes à partir des clés des objets de données
    const keys = Object.keys(thList[0]);
    /*   const dynamicColumns = keys.map((key) => ({
      Header: key,
      accessor: key,
    }));

    return dynamicColumns; */
    const dynamicColumns = keys.map((key, index) => {
      let headerGroup;
      if (index < 2) {
        headerGroup = "infos";
      } else {
        headerGroup = "date";
      }

      return {
        Header: key,
        accessor: key,
        headerGroup, // Utiliser le nom du header group correspondant
        width: 90,
      };
    });

    return [
      {
        Header: "Information",
        columns: dynamicColumns.slice(0, 2), // Les 4 premières colonnes pour le header group "Infos"
        sticky: "left",
      },
      {
        Header: "Date",
        columns: dynamicColumns.slice(2), // Les colonnes restantes pour le header group "Date"
      },
    ];
  }, [thList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter);

  const { globalFilter } = state;

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await instance.get("/client/all/combine");
        setClients(
          res?.data.reduce((acc, curr) => {
            const { id, name, row } = curr;
            const cell = row?.map((el) => {
              return el?.cell;
            });
            const flat = cell?.flat();
            const result = Object.values(
              flat.reduce((acc, obj) => {
                const { date, value } = obj;
                if (!acc[date]) {
                  acc[date] = { date, value };
                } else {
                  acc[date].value += value;
                }
                return acc;
              }, {})
            );
            result.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });
            const totalValue = result.reduce(
              (acc, curr) => acc + parseFloat(curr.value),
              0.0
            );
            acc = acc.concat({
              id,
              name,
              result,
              total_value: totalValue,
            });
            return acc;
          }, [])
        );
        const formatData = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }

          const result = data.flatMap((curr) => {
            const { id, firstName, lastName, total_bdc, roles, row, image } =
              curr;

            const flat = row?.flatMap((el) => el?.cell) || [];
            return flat.map(({ date, value }) => ({ date, value }));
          });
          const totalValue = result.reduce((acc, curr) => acc + curr.value, 0);
          result.sort((a, b) => a.date.localeCompare(b.date));

          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});

          return [
            {
              Nom: data[0].name,
              "Total BDC": data[0].total_bdc,
              ...formattedData,
            },
          ];
        };
        const formatData2 = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }

          const result = data.flatMap((curr) => {
            const { id, firstName, lastName, total_bdc, roles, row, image } =
              curr;

            const flat = row?.flatMap((el) => el?.cell) || [];
            return flat.map(({ date, value }) => ({ date, value }));
          });
          const totalValue = result.reduce((acc, curr) => acc + curr.value, 0);
          result.sort((a, b) => a.date.localeCompare(b.date));

          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});

          return [
            {
              ...formattedData,
            },
          ];
        };
        setThList(formatData(res?.data));
        setThAll(formatData2(res?.data));

        setDataAll(
          res?.data?.map((curr) => {
            const { id, name, row } = curr;
            const cell = row?.flat().map((el) => {
              return el?.cell;
            });
            const flat = cell?.flat();
            const aggregatedResult = flat.reduce((acc, obj) => {
              let { date, value } = obj;
              if (!acc[date]) {
                acc[date] = 0.0;
              }
              acc[date] += parseFloat(value);
              return acc;
            }, {});
            const result = Object.entries(aggregatedResult).map(
              ([date, value]) => ({
                date,
                value,
              })
            );
            result.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });
            return {
              ...Object.fromEntries(
                result.map(({ date, value }) => [date, value])
              ),
            };
          })
        );
        setDataToDisplay(
          res?.data?.map((curr) => {
            const { id, name, row } = curr;
            const cell = row?.flat().map((el) => {
              return el?.cell;
            });
            const flat = cell?.flat();
            const aggregatedResult = flat.reduce((acc, obj) => {
              let { date, value } = obj;
              if (!acc[date]) {
                acc[date] = 0.0;
              }
              acc[date] += parseInt(value);
              return acc;
            }, {});
            const result = Object.entries(aggregatedResult).map(
              ([date, value]) => {
                return { date, value };
              }
            );
            const totalValue = result.reduce(
              (acc, curr) => acc + parseInt(curr.value),
              0
            );
            result.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });
            return {
              id,
              Nom: name,
              "Total BDC": `${totalValue.toLocaleString()}€`,
              ...Object.fromEntries(
                result.map(({ date, value }) => [
                  date,
                  `${value.toLocaleString()}€`,
                ])
              ),
            };
          })
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("🚀 ~ file: Clients.js:20 ~ fetchClients ~ error", error);
      }
    };
    fetchClients();
  }, []);

  useEffect(() => {
    // Calculer le total de toutes les valeurs de total_value
    const total = clients.reduce((acc, curr) => {
      return acc + parseInt(curr?.total_value.toFixed(2));
    }, 0.0);
    setAllTotalValue(total);
  }, [clients]);

  /*   const column = clients.map((el, index) => {
    const elDate = el.result.map((date, index) => {
      return { date: date.date, index: index };
    });
    return {
      id: el.id,
      name: el.name,
      elDate,
    };
  }); */

  /*   const rows =
    clients &&
    clients.map((element) => {
      const id = element.id;
      return (
        <tr key={element.id}>
          <td
            className="td-clients"
            onClick={() => {
              navigate(`/clients/${id}`);
            }}
          >
            {element.name}
          </td>
          <td
            className="td-employee"
            onClick={() => {
              navigate(`/clients/${id}`);
            }}
          >
            {element.total_value}€
          </td>
          {element.result.map((el, index) => {
            return (
              <td key={index} className={`table-cell ${getClass(el.value)}`}>
                {el.value ? el.value : 0}€
              </td>
            );
          })}
        </tr>
      );
    });
 */

  //console.log("globalFilter :>> ", globalFilter);
  if (dataToDisplay.length === 0 && !loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        Chargement en cours...
      </div>
    );
  }

  return (
    <>
      <div className="container-all-clients">
        <h1>Clients</h1>
        <div className="div-total-create-client">
          <div className="descrip-totals">
            <NavClient />

            {/* <p>Total bon de commandes attribué</p>
            <img src={euro} alt="euro" />

            <span>
              {allTotalValue ? `${allTotalValue.toLocaleString()}€` : `${0.0}€`}{" "}
            </span> */}
          </div>
          {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
          <div className="filter-create-client">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <CreateClient />
          </div>

          {/* </div> */}
        </div>
        {/* {loading ? null : (
          <div>
            <span className="total-mois">Total par mois</span>
            <DisplayAllTotal dataAll={dataAll} thAll={thAll} />
          </div>
        )} */}

        {/* <input
          type="text"
          value={filterValue}
          onChange={handleFilterChange}
          placeholder="Rechercher un client..."
        /> */}

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
              marginBottom: "5rem",
            }}
          >
            <LoaderComp />
          </div>
        ) : (
          <div>
            <StickyTable height={"100%"}>
              <table className="table" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(`/clients/${row.original.id}`)}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              <div className={getClass(cell.value)}>
                                {cell.render("Cell")}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </StickyTable>
            {isVisible && (
              <button className="scroll-to-top" onClick={scrollToTop}>
                ↑
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Clients;
