import React, { useEffect, useState } from "react";
import instance from "../axios/global";
import { useParams } from "react-router-dom";
import { getClass } from "../utils/utils";
import { useNavigate } from "react-router-dom";

import { useTable } from "react-table";

import LoaderComp from "../components/LoaderComp";
import StickyTable from "react-sticky-table-thead";
import clientlogo from "../assets/astro.jpg";
import DisplayAllTotal from "../components/DisplayAllTotal";

const ClientBDC = () => {
  const { id } = useParams();
  console.log("🚀 ~ file: ClientBDC.js:18 ~ ClientBDC ~ id:", id);
  const [clientInfos, setClientInfos] = useState("");
  const [bdcByClient, setBdcByClient] = useState("");
  const [allTotalValue, setAllTotalValue] = useState(0.0);
  const [thList, setThList] = useState([]);
  const [chartDataFetch, setChartDataFetch] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  console.log(
    "🚀 ~ file: ClientBDC.js:25 ~ ClientBDC ~ dataToDisplay:",
    dataToDisplay
  );
  const [loading, setLoading] = useState(false);
  const [dataAll, setDataAll] = useState([]);
  const [thAll, setThAll] = useState([]);
  const [vueSelectionnee, setVueSelectionnee] = useState("tableau");

  // Fonction pour changer la vue lorsqu'un bouton est cliqué
  const changerVue = (nouvelleVue) => {
    setVueSelectionnee(nouvelleVue);
  };
  // Afficher la valeur de allTotalValue dans votre composant React

  const navigate = useNavigate();
  useEffect(() => {
    const fetchClient = async () => {
      try {
        const res = await instance.get(`/client/${id}`);
        /*         console.log(
          "🚀 ~ file: ClientBDC.js:48 ~ fetchBdcByClient ~ res",
          res.data
        ); */
        setClientInfos(res.data);
      } catch (error) {
        console.log(
          "🚀 ~ file: ClientBDC.js:49 ~ fetchBdcByClient ~ error",
          error
        );
      }
    };
    fetchClient();
  }, []);

  useEffect(() => {
    const fetchBdcByClient = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`/bdc/client/summarize/${id}`);
        /*         setBdcByClient(
          res?.data.reduce((acc, curr) => {
            const { id, accounting_code, name, user } = curr;
            const row = user?.map((el) => {
              return el?.row;
            });
            const flat = row?.flat();

            const cell = flat?.map((el) => {
              return el?.cell;
            });

            const cellFlat = cell?.flat();
            const result = Object.values(
              cellFlat.reduce((acc, obj) => {
                const { date, value } = obj;
                if (!acc[date]) {
                  acc[date] = { date, value };
                } else {
                  acc[date].value += value;
                }
                return acc;
              }, {})
            );
            result.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });
            acc = acc.concat({
              id,
              accounting_code,
              name,
              result,
            });
            return acc;
          }, [])
        ); */
        const formatData = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }
          console.log("data :>> ", data);
          const result = data.flatMap((curr) => {
            const { id, accounting_code, name, user } = curr;
            const row = user?.map((el) => {
              return el?.row;
            });
            const flat = row?.flat();
            const cell = flat?.map((el) => {
              return el?.cell;
            });
            const cellFlat = cell?.flat();
            return cellFlat?.flatMap(({ date, value }) => ({ date, value }));
          });
          result.sort((a, b) => a.date.localeCompare(b.date));

          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});

          return [
            {
              //id: data[0].id,
              /*  ...(data?.length > 0
                ? { accounting_code: data[0].accounting_code }
                : {}), */
              "Code comptable": data[0].accounting_code,
              // ...(data?.length > 0 ? { name: data[0].name } : {}),
              Nom: data[0].name,
              // ...(data?.length > 0 ? { total_value: data[0].total_value } : {}),
              "Valeur total": data[0].total_value,
              ...formattedData,
            },
          ];
        };
        const formatData2 = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }
          const result = data.flatMap((curr) => {
            const { id, accounting_code, name, user } = curr;
            const row = user?.map((el) => {
              return el?.row;
            });
            const flat = row?.flat();
            const cell = flat?.map((el) => {
              return el?.cell;
            });
            const cellFlat = cell?.flat();
            return cellFlat?.flatMap(({ date, value }) => ({ date, value }));
          });
          result.sort((a, b) => a.date.localeCompare(b.date));

          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});

          return [
            {
              ...formattedData,
            },
          ];
        };
        setThList(formatData(res?.data));
        setThAll(formatData2(res?.data));

        setBdcByClient(
          res?.data.reduce((acc, curr) => {
            const { id, accounting_code, name, user } = curr;
            const row = user?.map((el) => {
              return el?.row;
            });
            const flat = row?.flat();

            const cell = flat?.map((el) => {
              return el?.cell;
            });

            const cellFlat = cell?.flat();
            const result = Object.values(
              cellFlat.reduce((acc, obj) => {
                const { date, value } = obj;
                if (!acc[date]) {
                  acc[date] = { date, value };
                } else {
                  acc[date].value += value;
                }
                return acc;
              }, {})
            );
            result.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });

            const totalValue = result.reduce(
              (acc, curr) => acc + parseInt(curr.value),
              0.0
            );
            acc = acc.concat({
              id,
              accounting_code,
              name,
              result,
              total_value: totalValue, // ajouter la clé total_value avec la somme des valeurs
            });
            return acc;
          }, [])
        );
        setDataAll(
          res?.data.reduce((acc, curr) => {
            const { user } = curr;
            const row = user?.map((el) => {
              return el?.row;
            });
            const flat = row?.flat();

            const cell = flat?.map((el) => {
              return el?.cell;
            });

            const cellFlat = cell?.flat();
            const result = Object.values(
              cellFlat.reduce((acc, obj) => {
                const { date, value } = obj;
                if (!acc[date]) {
                  acc[date] = { date, value };
                } else {
                  acc[date].value += value;
                }
                return acc;
              }, {})
            );

            result.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });
            acc = acc.concat({
              ...Object.fromEntries(
                result.map(({ date, value }) => [date, value])
              ),
            });
            return acc;
          }, [])
        );
        setDataToDisplay(
          res?.data.reduce((acc, curr) => {
            console.log("res.data :>>  iciciciciicic", res.data);
            const { id, accounting_code, name, user } = curr;
            const row = user?.map((el) => {
              return el?.row;
            });
            const flat = row?.flat();

            const cell = flat?.map((el) => {
              return el?.cell;
            });

            const cellFlat = cell?.flat();
            const result = Object.values(
              cellFlat.reduce((acc, obj) => {
                const { date, value } = obj;
                if (!acc[date]) {
                  acc[date] = { date, value: parseInt(value.toFixed(2)) };
                } else {
                  acc[date].value += parseInt(value.toFixed(2));
                }
                return acc;
              }, {})
            );

            result.sort((a, b) => {
              if (a.date < b.date) {
                return -1;
              } else if (a.date > b.date) {
                return 1;
              } else {
                return 0;
              }
            });

            const totalValue = result.reduce(
              (acc, curr) => acc + parseInt(curr.value),
              0.0
            );
            acc = acc.concat({
              id,
              "Code comptable": accounting_code,
              Nom: name,
              ...Object.fromEntries(
                result.map(({ date, value }) => [
                  date,
                  `${value.toLocaleString()}€`,
                ])
              ),
              "Valeur total": `${totalValue.toLocaleString()}€`, // ajouter la clé total_value avec la somme des valeurs
            });
            return acc;
          }, [])
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(
          "🚀 ~ file: ClientBDC.js:49 ~ fetchBdcByClient ~ error",
          error
        );
      }
    };
    fetchBdcByClient();
  }, []);

  useEffect(() => {
    // Calculer le total de toutes les valeurs de total_value
    const total =
      bdcByClient &&
      bdcByClient.reduce(
        (acc, curr) => acc + parseFloat(curr?.total_value.toFixed(2)),
        0.0
      );
    setAllTotalValue(total);
  }, [bdcByClient]);

  const data = React.useMemo(() => dataToDisplay, [dataToDisplay]);
  const columns = React.useMemo(() => {
    if (!thList || thList.length === 0) {
      return []; // Retourner une liste de colonnes vide si thList est undefined ou vide
    }

    // Générer dynamiquement les colonnes à partir des clés des objets de données
    const keys = Object.keys(thList[0]);
    /*     const dynamicColumns = keys.map((key) => ({
      Header: key,
      accessor: key,
    }));

    return dynamicColumns; */
    const dynamicColumns = keys.map((key, index) => {
      let headerGroup;
      if (index < 3) {
        headerGroup = "infos";
      } else {
        headerGroup = "date";
      }

      return {
        Header: key,
        accessor: key,
        headerGroup, // Utiliser le nom du header group correspondant
        width: 90,
      };
    });

    return [
      {
        Header: "Information",
        columns: dynamicColumns.slice(0, 3), // Les 3 premiéres colonnes pour le header group "Infos", // Les 4 premières colonnes pour le header group "Infos"
        sticky: "left",
      },
      {
        Header: "Date",
        columns: dynamicColumns.slice(3), // Les colonnes restantes pour le header group "Date"
      },
    ];
  }, [thList]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  /*   const column =
    bdcByClient &&
    bdcByClient.map((el, index) => {
      const elDate = el.result.map((date, index) => {
        return { date: date.date, index: index };
      });
      return {
        id: el.id,
        name: el.name,
        accounting_code: el.accounting_code,
        elDate,
      };
    });
  console.log("🚀 ~ file: ClientBDC.js:94 ~ ClientBDC ~ column:", column);
  const rows =
    bdcByClient &&
    bdcByClient.map((element) => {
      const id = element.id;
      return (
        <tr key={element.id}>
          <td>{element.accounting_code}</td>
          <td
            className="td-employee"
            onClick={() => {
              navigate(`/clients/${id}`);
            }}
          >
            {element.name}
          </td>
          <td
            className="td-employee"
            onClick={() => {
              navigate(`/clients/${id}`);
            }}
          >
            {element.total_value}€
          </td>
          {element.result.map((el, index) => {
            return (
              <td key={index} className={`table-cell ${getClass(el.value)}`}>
                {el.value ? el.value : 0}€
              </td>
            );
          })}
        </tr>
      );
    }); */
  return (
    <div className="clientBDC">
      <div className="container-header-clientbdc">
        <div className="logo-code">
          <img src={clientlogo} alt="img" />
        </div>
        <div className="div-client-infos">
          <div>
            <h1>{clientInfos.name}</h1>
          </div>
          <div className="div-total-create-client">
            <div className="descrip-totals">
              <p>Total bons de commandes {clientInfos.name} :</p>
              {/* <img src={euro} alt="euro" /> */}

              <span>
                {allTotalValue ? `${allTotalValue.toLocaleString()}€` : `${0}€`}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>

      <section className="table-bdcbyclient">
        <div className="nav-control">
          <div className="nav-userbdc">
            <button onClick={() => changerVue("tableau")}>Client</button>
            <button onClick={() => changerVue("global")}>Global</button>
          </div>
        </div>
        {/*         <Table>
          <thead className="sticky-table-head">
            <tr>
              <th>accounting_code</th>
              <th>name</th>
              <th>Total_Value</th>
              {Array.isArray(column) &&
                column.length > 0 &&
                column.map(({ id, name, accounting_code, elDate }) => (
                  <>
                    {elDate?.map(({ date, index }) => (
                      <th key={index}>{date}</th>
                    ))}
                  </>
                ))}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table> */}

        {dataToDisplay.length === 0 ? (
          <div>Aucun BDC à afficher pour ce client</div>
        ) : loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <LoaderComp />
          </div>
        ) : (
          <div>
            {vueSelectionnee === "tableau" ? (
              <>
                <StickyTable height={"100%"}>
                  <table className="table" {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate(`details-bdc/${row.original.id}`);
                                  }}
                                >
                                  <div className={getClass(cell.value)}>
                                    {cell.render("Cell")}
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </StickyTable>
              </>
            ) : (
              <div>
                <span className="total-mois">Total par mois</span>
                <DisplayAllTotal dataAll={dataAll} thAll={thAll} />
              </div>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default ClientBDC;
