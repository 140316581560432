import React, { useEffect, useState } from "react";
import instance from "../axios/global";
import { useParams, useNavigate } from "react-router-dom";
import { useTable } from "react-table";
import * as moment from "moment";
import Charts from "../components/Charts";
import imguser from "../assets/user.jpg";
import { getClass } from "../utils/utils";
import LoaderComp from "../components/LoaderComp";
import DisplayAllTotal from "../components/DisplayAllTotal";
import StickyTable from "react-sticky-table-thead";
import { SegmentedControl } from "@mantine/core";
import { Switch } from "@mantine/core";
import "dayjs/locale/fr";

const BdcByUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userInfos, setUserInfos] = useState("");
  const [bdcList, setBdcList] = useState([]);
  const [thList, setThList] = useState([]);
  const [chartDataFetch, setChartDataFetch] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataAll, setDataAll] = useState([]);
  const [thAll, setThAll] = useState([]);
  const [gape, setGape] = useState("15");
  const [checked, setChecked] = useState();
  const [vueSelectionnee, setVueSelectionnee] = useState("tableau");
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    // Fonction pour faire défiler jusqu'en haut de la page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Pour une animation de défilement en douceur
    });
  };
  window.addEventListener("scroll", handleScroll);
  // Fonction pour changer la vue lorsqu'un bouton est cliqué
  const changerVue = (nouvelleVue) => {
    setVueSelectionnee(nouvelleVue);
  };

  /*   const uniqueArray = chartDataFetch.reduce((acc, val) => acc.concat(val), []);

  const tableauTransforme = uniqueArray.map((objet) => {
    const date = moment(objet.date, "YYYY-MM").format("MMMM-YYYY");
    return { ...objet, date };
  });
  const tableauReduit = tableauTransforme.reduce((acc, objet) => {
    const index = acc.findIndex((element) => element.date === objet.date);
    if (index === -1) {
      acc.push(objet);
    } else {
      acc[index].value += objet.value;
    }
    return acc;
  }, []); try */

  const tableauReduit = chartDataFetch
    ?.reduce((acc, val) => acc.concat(val), [])
    .reduce((acc, objet) => {
      const date = moment(objet.date, "YYYY-MM").format("MMMM-YYYY");
      const index = acc.findIndex((element) => element.date === date);
      if (index === -1) {
        acc.push({ date, value: objet.value });
      } else {
        acc[index].value += objet.value;
      }
      return acc;
    }, [])
    .sort(
      (a, b) =>
        moment(a.date, "MMMM-YYYY").toDate() -
        moment(b.date, "MMMM-YYYY").toDate()
    );
  const handleRightClick = (event, id) => {
    const cmdOrCtrlPressed = event.metaKey || event.ctrlKey;
    if (cmdOrCtrlPressed) {
      event.preventDefault();
      const url = `/bdc/update/${id}`;
      const newTab = window.open(url, "_blank", "noopener,noreferrer");
      if (newTab) {
        newTab.opener = window;
      }
    }
  };

  useEffect(() => {
    const fetchUserInfos = async () => {
      try {
        const res = await instance.get(`/user/${id}`);
        setUserInfos(res?.data);
        // console.log("res.data :>> ", res.data);
        setChecked(res?.data?.isActive);
      } catch (error) {
        console.log(
          "🚀 ~ file: ClientBDC.js:49 ~ fetchBdcByClient ~ error",
          error
        );
      }
    };
    fetchUserInfos();
  }, [id]);

  useEffect(() => {
    const handleChangeUserStatus = async () => {
      try {
        const res = await instance.put(`/user/status/${id}`, {
          status: checked,
        });
        setUserInfos(res.data);
        const fetchUserInfos = async () => {
          try {
            const res = await instance.get(`/user/${id}`);
            setUserInfos(res?.data);
            console.log("res.data :>> ", res.data);
            setChecked(res?.data?.isActive);
          } catch (error) {
            console.log(
              "🚀 ~ file: ClientBDC.js:49 ~ fetchBdcByClient ~ error",
              error
            );
          }
        };
        fetchUserInfos();
        console.log("send");
      } catch (error) {
        console.log(
          "🚀 ~ file: ClientBDC.js:49 ~ fetchBdcByClient ~ error",
          error
        );
      }
    };
    handleChangeUserStatus();
  }, [id, checked]);

  useEffect(() => {
    const fetchBdcByUser = async () => {
      setLoading(true);
      try {
        const res = await instance.get(`user/bdc/all/${id}?gape=${gape}`);
        setBdcList(res?.data);
        console.log("res.data :>> ", res.data);
        const formatData = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }
          const result = data.flatMap((curr) => {
            const { cell } = curr;

            /*     const flat = cell?.flatMap((el) => el?.cell) || [];
            console.log("flat :>> ", flat); */
            return cell?.flatMap(({ date, value }) => ({ date, value }));
          });

          result.sort((a, b) => a.date.localeCompare(b.date));

          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});
          return [
            {
              //name: data[0].name,
              ...(data?.length > 0 ? { Nom: data[0].name } : {}),
              ...(data.length > 0 ? { "Numéro bdc": data[0].bdc.number } : {}),
              ...(data?.length > 0
                ? { "Nom du client": data[0].clientName }
                : {}),
              ...formattedData,
            },
          ];
        };
        const formatData2 = (data) => {
          if (!data) {
            return [{ id: "c'est null" }];
          }
          const result = data.flatMap((curr) => {
            const { id, name, cell } = curr;

            /*     const flat = cell?.flatMap((el) => el?.cell) || [];
            console.log("flat :>> ", flat); */
            return cell?.flatMap(({ date, value }) => ({ date, value }));
          });

          result.sort((a, b) => a.date.localeCompare(b.date));

          const formattedData = result.reduce((acc, { date, value }) => {
            acc[date] = value;
            return acc;
          }, {});
          return [
            {
              ...formattedData,
            },
          ];
        };
        setThList(formatData(res?.data));
        setThAll(formatData2(res?.data));
        setDataAll(
          res?.data?.map((curr) => {
            const { name, cell, clientName } = curr;
            const flat = cell?.flat();
            const result = flat.flatMap(({ date, value }) => ({ date, value }));
            result.sort((a, b) => a.date - b.date);

            const transformedResult = result.reduce((acc, { date, value }) => {
              acc[date] = value;
              return acc;
            }, {});

            return {
              ...transformedResult,
            };
          })
        );
        setDataToDisplay(
          res?.data?.map((curr) => {
            const {
              name,
              cell,
              clientId,
              bdcId,
              bdc: { number, client_name },
            } = curr;
            console.log("🚀 ~ res?.data?.map ~ curr:", curr);

            const flat = cell?.flat();
            const result = flat.flatMap(({ date, value }) => ({ date, value }));
            result.sort((a, b) => a.date - b.date);

            const transformedResult = result.reduce((acc, { date, value }) => {
              acc[date] = `${parseInt(value).toLocaleString()}€`; /* `${
                value.toLocaleString( undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              } )
              }€`; */
              return acc;
            }, {});
            return {
              id,
              clientId,
              bdcId,
              Nom: name,
              "Numéro bdc": number,
              "Nom du client": client_name,
              ...transformedResult,
            };
          })
        );
        setChartDataFetch(
          res?.data?.map((el) => {
            return el.cell;
          })
        );
        setLoading(false);
      } catch (error) {
        console.log(
          "🚀 ~ file: ClientBDC.js:49 ~ fetchBdcByClient ~ error",
          error
        );
        setLoading(false);
      }
    };
    fetchBdcByUser();
  }, [gape, id]);

  console.log("dataToDisplay :>> ", dataToDisplay);

  const data = React.useMemo(() => dataToDisplay, [dataToDisplay]);
  const columns = React.useMemo(() => {
    console.log("thList :>> ", thList);
    if (!thList || thList.length === 0) {
      return []; // Retourner une liste de colonnes vide si thList est undefined ou vide
    }

    // Générer dynamiquement les colonnes à partir des clés des objets de données
    const keys = Object.keys(thList[0]);
    /*     const dynamicColumns = keys.map((key) => ({
      Header: key,
      accessor: key,
    }));

    return dynamicColumns; */
    const dynamicColumns = keys.map((key, index) => {
      let headerGroup;
      if (index < 4) {
        headerGroup = "infos";
      } else {
        headerGroup = "date";
      }

      return {
        Header: key,
        accessor: key,
        headerGroup, // Utiliser le nom du header group correspondant
        width: 100,
      };
    });

    return [
      {
        Header: "Information",
        columns: dynamicColumns.slice(0, 3), // Les 4 premières colonnes pour le header group "Infos"
        sticky: "left",
      },
      {
        Header: "Date",
        columns: dynamicColumns.slice(3), // Les colonnes restantes pour le header group "Date"
      },
    ];
  }, [thList]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  const dataChart = {
    labels:
      tableauReduit.length !== 0 &&
      tableauReduit?.map((data) => {
        return data.date;
      }),
    datasets: [
      {
        label: "Valeur du mois ",
        data:
          tableauReduit.length !== 0 &&
          tableauReduit?.map((data) => data.value),
        backgroundColor: ["#7398FF"],
        borderColor: "#0A4BFF",
        lignThickness: 45,
        borderRadius: 7,
        hoverBackgroundColor: "#7398FF",
        pointStyle: "circle",
        pointRadius: 5,
        pointHoverRadius: 15,
        fill: true,
      },
    ],
  };

  console.log("rows :>> ", rows);
  return (
    <div className="userBDC">
      <header>
        <div className="container-user-infos">
          <div className="img-user-name">
            <img
              src={userInfos?.image?.length !== 0 ? imguser : userInfos?.image}
              alt="img"
            />
            <div className="user-infos">
              <h1>
                {userInfos?.firstName && userInfos?.firstName}{" "}
                {userInfos?.lastName && userInfos?.lastName}
              </h1>
              <h2>{userInfos?.entity && userInfos?.entity}</h2>
              <h3>{userInfos?.squad && userInfos?.squad}</h3>
              <h3>{userInfos?.isActive ? "Actif" : "Pas actif"}</h3>
              {userInfos ? (
                <Switch
                  // label="Active"
                  style={{ marginTop: "1rem", cursor: "pointer" }}
                  color="green"
                  onLabel="Actif"
                  offLabel="Pas actif"
                  checked={checked}
                  size="xl"
                  onChange={(event) => setChecked(event.currentTarget.checked)}
                />
              ) : null}
            </div>
          </div>
          <div className="container-user-stats">
            <span>
              Nombre total de bon de commande :
              <p> {userInfos?.number_of_bdc?.toLocaleString() || 0}</p>
            </span>
            <span>
              Montant total BDC :
              <p>{userInfos?.total_bdc?.toLocaleString() || 0}€ </p>
            </span>
            <span>
              Nombre de bon de commande ces 12 derniers mois :
              <p>
                {" "}
                {userInfos?.number_of_bdc_last_12_months?.toLocaleString() || 0}
              </p>
            </span>
            <span>
              Montant total BDC ces 12 derniers mois :
              <p>
                {userInfos?.total_bdc_last_12_months?.toLocaleString() || 0}€{" "}
              </p>
            </span>
          </div>
        </div>
        {dataToDisplay.length !== 0 ? (
          <div className="chart-container">
            <Charts chartData={dataChart && dataChart} />
          </div>
        ) : (
          ""
        )}
      </header>
      <section className="table-bdcbyclient" style={{ marginBottom: "3rem" }}>
        <div className="nav-control">
          <div className="nav-userbdc">
            <button onClick={() => changerVue("tableau")}>Employé</button>
            <button onClick={() => changerVue("global")}>Global</button>
          </div>
          <div className="container-gape">
            <SegmentedControl
              value={gape}
              onChange={setGape}
              data={[
                { label: "12", value: "12" },
                { label: "12 + 3", value: "15" },
                { label: "24", value: "24" },
                { label: "All", value: "all" },
              ]}
            />
          </div>
        </div>
        {/* {loading ? (
          <LoaderComp />
        ) : (
          <div>
            <span className="total-mois">Total par mois</span>
            <DisplayAllTotal dataAll={dataAll} thAll={thAll} />
          </div>
        )} */}
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <LoaderComp />
          </div>
        ) : (
          <div>
            {vueSelectionnee === "tableau" ? (
              <>
                <StickyTable height="100%">
                  <table className="table" {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              const cmdOrCtrlPressed =
                                event.metaKey || event.ctrlKey;
                              const { bdcId } = row.original;
                              if (!cmdOrCtrlPressed) {
                                navigate(`/bdc/update/${bdcId}`);
                              }
                              handleRightClick(event, bdcId);
                            }}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  <div className={getClass(cell.value)}>
                                    {cell.render("Cell")}
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </StickyTable>
              </>
            ) : (
              <div>
                <span className="total-mois">Total par mois</span>
                <DisplayAllTotal dataAll={dataAll} thAll={thAll} />
              </div>
            )}
          </div>
        )}
        {isVisible && (
          <button
            className="scroll-to-top"
            style={{ width: "2.5rem", height: "2.5rem" }}
            onClick={scrollToTop}
          >
            ↑
          </button>
        )}
      </section>
    </div>
  );
};

export default BdcByUser;
